import parse from 'html-react-parser';
import Image from 'next/image';
import tw, { css, styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const Wrapper = tw.div`w-full mb-5 lg:(mb-10)`;
// TextWrapper = styled.div`py-40 col-span-full lg:(col-end-7 col-span-3)`;
const TextWrapper = styled.div`
  ${tw`py-10 col-span-full lg:(col-end-7 col-span-2) text-gray-800`}
`;
const ImageWrapper = styled.div`
  ${tw`relative min-height[400px] col-span-full lg:col-span-4`}
`;
const StyledImage = styled(Image)`
  ${tw`w-full! h-auto`}
  img {
    ${tw`w-full! h-auto!`}
  }
`;
const Paragraph = styled.div`
  ${tw`line-height[140%]`}
`;
const Heading = styled.h3`
  ${tw`relative w-full pb-5 mb-4 text-lg uppercase lg:text-3xl text-primary`}
  span {
    ${tw`box-shadow[0 0 0 10px transparent] bg-gray-100 line-height[150%]`}
  }
`;
const ContentGrid = styled.div<{ $isInverted?: boolean }>`
  ${tw`relative w-full grid grid-cols-1 column-gap[100px] pt-20 pb-10 lg:(pb-5 pt-10 grid-cols-7 column-gap[40px])`}
  ${({ $isInverted }) =>
    $isInverted &&
    css`
      ${ImageWrapper} {
        ${tw`order-last!`}
      }
    `}
`;

export const ImageBlock = ({ fields }) => {
  const heading = findByShortname(fields, 'titolo')?.content.value ?? null;
  const paragraph = findByShortname(fields, 'paragraph')?.content.value ?? null;
  const image = findByShortname(fields, 'image')?.content?.value?.url ?? null;
  //const isInverted = findByShortname(fields, 'isInverted')?.content.value;

  return (
    <Wrapper>
      <MaxWidthContent>
        <ContentGrid /* $isInverted={isInverted} */>
          <ImageWrapper>
            {!!image && (
              <StyledImage
                src={image}
                alt={''}
                layout="fill"
                objectFit="contain"
                // objectFit="scale-down"
                // objectFit="none"
                /* objectPosition={isInverted ? '0 0' : '100 0'} */
              />
            )}
          </ImageWrapper>
          <TextWrapper>
            {!!heading && <Heading>{parse(heading)}</Heading>}
            {!!paragraph && (
              <Paragraph>
                <RichText>{parse(paragraph)}</RichText>
              </Paragraph>
            )}
          </TextWrapper>
        </ContentGrid>
      </MaxWidthContent>
    </Wrapper>
  );
};
