import { Organization, Person } from '.';

const handleCardStyle = (card, style: string) => {
  switch (style.toLowerCase()) {
    case 'persona':
      return <Person fields={card.fields} />;
    case 'organizzazioni':
    default:
      return <Organization fields={card.fields} />;
  }
};

export const CardRenderer = ({ card, style }) => handleCardStyle(card, style);
