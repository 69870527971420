export const data = {
  name: "Main Navigation",
  slug: "main-navigation",
  blocks: [
    {
      shortname: "main-menu-item-1",
      name: "Main Menu Item 1",
      position: 1,
      fields: [
        {
          shortname: "scopri",
          name: "Scopri Servizi",
          type: "link",
          content: {
            label: "Scopri Servizi",
            url: "/scopri",
            level: 1
          }
        },
        {
          shortname: "contatti",
          name: "Contatti",
          type: "link",
          content: {
            label: "Il Progetto",
            url: "/contatti",
            level: 1,
          }
        },
        {
          shortname: "welfare-beatrice",
          name: "Welfare Beatrice",
          type: "link",
          content: {
            label: "Welfare Aziendale",
            url: "/welfare-beatrice",
            level: 1,
          }
        },
        {
          shortname: "aziende",
          name: "Aziende",
          type: "link",
          content: {
            label: "Aziende",
            url: "/aziende",
            level: 1,
          }
        },
        {
          shortname: "community",
          name: "Community",
          type: "link",
          content: {
            label: "La Community",
            url: "/community",
            level: 1,
          }
        },
        {
          shortname: "news",
          name: "News",
          type: "link",
          content: {
            label: "News",
            url: "/news",
            level: 1,
          }
        },
      ]
    },
    {
      shortname: "accedi",
      name: "Accedi",
      type: "link",
      content: {
        label: "Accedi",
        url: "/login",
        level: 1,
      }
    },
    {
      shortname: "brand-logo",
      name: "Brand Logo",
      position: 2,
      fields: [
        {
          shortname: "logo",
          name: "Logo",
          type: "image",
          content: {
            alt: "Beatrice Logo",
            src: "/unnamed.png",
          }
        },
      ]
    }
  ]
}