import { ArrowDownIcon, XIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';
//import Accordion from '@uidu/accordion';

const Heading = tw.h4`font-bold text-lg mb-2.5 text-primary p-5 uppercase`;
const TitleWrapper = styled.div`
  ${tw`relative flex items-center justify-between transition duration-500 rounded-sm cursor-pointer text-primary ease`}
`;
const AnswerWrapper = tw.p`p-2.5 text-gray-800`;
const MenuButton = tw.button`p-5 mb-5 flex justify-between rounded`;

const Question = ({ question }) => {
  const title =
    findByShortname(question.fields, 'domanda')?.content?.value ?? null;
  const answer =
    findByShortname(question.fields, 'risposta')?.content?.value ?? null;
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  return (
    <div
      tw="overflow-hidden hover:shadow-lg py-5 rounded-lg"
      className="accordion"
    >
      <TitleWrapper
        tw="flex place-items-end"
        onClick={() => setMenuIsOpen((prev) => !prev)}
      >
        {!!title && <Heading>{title}</Heading>}
        <MenuButton>
          {menuIsOpen ? (
            <XIcon tw="w-6 h-6 text-primary" />
          ) : (
            <ArrowDownIcon tw="w-6 h-6  text-primary" />
          )}
        </MenuButton>
      </TitleWrapper>
      {menuIsOpen ? (
        <AnswerWrapper tw="group-focus:max-h-screen px-4 py-4 ease duration-500">
          {!!answer && <RichText tw="text-lg">{answer}</RichText>}
        </AnswerWrapper>
      ) : null}
    </div>
  );
};

export const AccordionBlock = ({ fields }) => {
  const questions =
    findByShortname(fields, 'domande-frequenti')?.content?.items ?? [];

  return (
    <MaxWidthContent tw="my-12 py-12 lg:w-2/3!">
      {questions.map((question, index) => (
        <Question question={question} key={`question-${index}`} />
      ))}
    </MaxWidthContent>
  );
};
