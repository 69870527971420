import { ButtonHTMLAttributes } from 'react';
import tw, { styled } from 'twin.macro';

type Color = 'white' | 'secondary' | 'primary';
type Size = 'small' | 'default';

const StyledButton = styled.button<{
  color?: Color;
  size?: Size;
  $isDisabled?: boolean;
}>`
  ${tw`text-sm font-semibold uppercase rounded`}
  ${({ color }) => {
    switch (color) {
      case 'white':
        return tw`bg-white text-primary hover:bg-primaryHover`;
      case 'primary':
        return tw`text-white bg-primary hover:bg-primaryHover`;
      case 'secondary':
        return tw`text-white bg-secondary hover:bg-secondaryHover`;
    }
  }}
  ${({ size }) => {
    switch (size) {
      case 'small':
        return tw`px-12 py-6 text-sm lg:(px-20 py-8)`;
      case 'default':
        return tw`px-4 py-3 lg:(px-6 py-2.5)`;
    }
  }}
  
  ${({ $isDisabled }) => $isDisabled && tw`opacity-50 pointer-events-none`}
`;

interface Props {
  color?: Color;
  size?: Size;
  disabled?: boolean;
}

export const Button: React.FC<Props & ButtonHTMLAttributes<any>> = ({
  color,
  size,
  disabled,
  children,
  ...rest
}) => {
  return (
    <StyledButton
      color={color ?? 'primary'}
      size={size ?? 'default'}
      disabled={disabled}
      $isDisabled={disabled}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};
