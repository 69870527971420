import parse from 'html-react-parser';
import Image from 'next/image';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const Paragraph = styled.div`
  ${tw`line-height[160%] text-gray-800 col-span-full px-5 py-5 lg:(col-span-2 py-5)`}
`;
const ImageWrapper = styled.div`
  ${tw`hidden md:block relative min-height[400px] col-span-1 lg:col-span-2`}
`;
const ContentGrid = styled.div`
  ${tw`relative w-full grid grid-cols-1 column-gap[100px] lg:(pb-5 pt-10 grid-cols-4 column-gap[40px])`}
`;
const StyledImage = styled(Image)`
  ${tw`w-full! h-auto`}
  img {
    ${tw`w-full! h-auto!`}
  }
`;

export const ContactsInfoBlock = ({ fields }) => {
  const brandLogo = findByShortname(fields, 'logo')?.content?.value ?? '';
  const image = findByShortname(fields, 'logo')?.content?.value?.url ?? null;

  const label = findByShortname(fields, 'text')?.content.value ?? null;

  return (
    <div>
      <MaxWidthContent>
        <ContentGrid tw="items-center">
          <ImageWrapper>
            {!!image && (
              <StyledImage
                src={image}
                alt={''}
                layout="fill"
                objectFit="contain"
                // objectFit="scale-down"
                // objectFit="none"
                /* objectPosition={isInverted ? '0 0' : '100 0'} */
              />
            )}
          </ImageWrapper>
          {!!label && (
            <Paragraph>
              <RichText>{parse(label)}</RichText>
            </Paragraph>
          )}
        </ContentGrid>
      </MaxWidthContent>
    </div>
  );
};
