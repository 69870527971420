import parse from 'html-react-parser';
import Image from 'next/image';
import { Heading } from 'styles/styled';
import tw, { styled } from 'twin.macro';
import QuoteSVG from '../../assets/icons/quote.svg';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const Wrapper = tw.div`w-full my-5 grid grid-cols-1 gap-3 lg:(gap-10 my-10 grid-cols-8)`;
const ColumnLeft = tw.div`text-gray-800`;
const ColumnRight = tw.div``;
const HiglightText = tw.div`font-bold text-secondary relative text-lg lg:text-2xl line-height[150%]! bg-white bg-opacity-80 p-3 -mt-28 lg:(ml-8) rounded-lg shadow-lg z-20 w-full py-10 px-10`;
const ImageWrapper = styled.div`
  ${tw`relative z-10 block w-full min-height[400px]`}
  > div {
    position: unset !important;
  }
`;

const StyledImage = styled(Image)`
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: auto !important;
`;

export const ImageHighlightsBlock = ({ fields }) => {
  const title = findByShortname(fields, 'titolo')?.content.value ?? null;
  const paragraph = findByShortname(fields, 'paragraph')?.content.value ?? null;
  const higlightText = findByShortname(fields, 'highlight-text')?.content
    ?.value;
  const imageHighlight =
    findByShortname(fields, 'image')?.content?.value?.url ?? null;

  return (
    <MaxWidthContent>
      <div tw="grid grid-cols-1 gap-4 my-4 md:(grid-cols-2 gap-8 my-16)">
        <ColumnLeft>
          {!!title && <Heading tw="text-left mb-5">{parse(title)}</Heading>}
          {!!paragraph && (
            <RichText tw="prose-lg lg:prose-lg">{parse(paragraph)}</RichText>
          )}
        </ColumnLeft>
        <ColumnRight>
          {
            <ImageWrapper>
              {!!imageHighlight && (
                <StyledImage src={imageHighlight} alt={''} layout="fill" />
              )}
            </ImageWrapper>
          }
          {!!higlightText && (
            <HiglightText>
              <QuoteSVG tw="w-10 h-10 mb-5" />
              {parse(higlightText)}
            </HiglightText>
          )}
        </ColumnRight>
      </div>
    </MaxWidthContent>
  );
};
