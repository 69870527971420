import { useEffect, useState } from 'react';
import { A11y, Autoplay, Swiper as ISwiper, SwiperOptions } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import tw, { styled } from 'twin.macro';

// install Swiper modules
ISwiper.use([A11y, Autoplay]);

const Wrapper = tw.div`h-full w-full relative`;

const PaginationWrapper = tw.div`w-full flex flex-col place-items-end justify-center md:mt-20`;

const NavigationWrapper = tw.div`absolute right-0 bottom-0 top-0 left-0 z-10 flex items-center justify-between cursor-pointer`;

const StyledSwiper = styled(Swiper)<{
  $hasPagination?: boolean;
  $centerVertically?: boolean;
  $direction?: string;
}>`
  /* ${tw`flex items-center h-auto!`} */
  ${tw`h-full`}
  .swiper-wrapper {
    ${tw`flex h-full`}
    ${({ $centerVertically }) => $centerVertically && tw`items-center`}
  }
  .swiper-slide {
    ${tw`flex items-center`}
  }
  ${({ $hasPagination }) => $hasPagination && tw`mx-60`}
`;

const Bullet = styled.span<{ $active: boolean }>`
  ${tw`lg:(width[15px] height[15px]) width[11px] height[11px] m-3 border border-primary rounded-full bg-white`}
  ${({ $active }) => !!$active && tw`bg-primary`}
`;

interface Props {
  slides?: JSX.Element[];
  slidesPerView?: number;
  hasPagination?: boolean;
  hasNavigation?: boolean;
  //direction?: string;
  loop?: boolean;
  centerVertically?: boolean;
  slidesPerGroup?: number;
  initialSlide?: number;
  loopedSlides?: number;
  autoplay?: any;
  breakpoints?: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  };
}

export const SliderHome: React.FC<Props> = ({
  slides,
  slidesPerView = 1,
  hasPagination,
  hasNavigation,
  breakpoints,
  autoplay,
  loop = false,
  centerVertically = true,
  slidesPerGroup = 1,
  initialSlide = 1,
  loopedSlides = 0,
  ...rest
}) => {
  const [swiperInstance, setSwiperInstance] = useState<ISwiper | null>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  useEffect(() => {
    if (!slides || !swiperInstance || !swiperInstance.activeIndex)
      return setActiveSlide(0);
    swiperInstance?.slideTo(activeSlide);
    swiperInstance?.init();
    if (autoplay) swiperInstance.autoplay.start();
  }, [activeSlide, swiperInstance, slides, autoplay]);

  return (
    <>
      {slides && (
        <Wrapper>
          <NavigationWrapper>
            {!!hasPagination && slides.length > 1 && (
              <PaginationWrapper>
                {!!swiperInstance &&
                  slides.map((_, index: number) => (
                    <Bullet
                      key={`bullet-${index}`}
                      $active={activeSlide === index}
                      onClick={() => swiperInstance.slideTo(index)}
                    />
                  ))}
              </PaginationWrapper>
            )}
          </NavigationWrapper>
          <StyledSwiper
            //direction={'vertical'}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            spaceBetween={20}
            threshold={2}
            centeredSlides
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerGroup}
            updateOnWindowResize
            autoplay={autoplay}
            loop={loop}
            initialSlide={initialSlide}
            loopAdditionalSlides={0}
            loopedSlides={loopedSlides}
            onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
            breakpoints={breakpoints || {}}
            $centerVertically={centerVertically}
            {...rest}
            direction="vertical"
          >
            {slides?.map((slide, index) => (
              <SwiperSlide key={`slide-${index}`} virtualIndex={activeSlide}>
                {slide}
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </Wrapper>
      )}
    </>
  );
};
