import tw, { styled } from 'twin.macro';

// Use this component to wrap content that needs global max-width
export const RichText = styled.div`
  ${tw`w-full m-0`}
  h3 {
    ${tw`text-xl`}
  }
  h4 {
    ${tw`text-lg`}
  }
  > div {
    ${tw`mb-4 lg:mb-20`}
  }
  p {
    ${tw`line-height[150%] empty:mb-4 mb-2`}
  }
`;
