import tw, { css, styled } from 'twin.macro';
import { Link } from '..';
import { findByShortname } from '../../../utils/common';

const ContentWrapper = tw.div`w-full p-2 md:p-4 flex flex-col justify-start`;
const Title = tw.h3`text-base font-semibold text-gray-800`;
const ImageWrapper = styled.img`
  ${tw`flex-shrink-0 object-cover w-full h-24 bg-center bg-cover lg:h-28`}
`;
const CardWrapper = styled(Link)<{ $featuredImage: string }>`
  ${tw`flex flex-col w-full overflow-hidden transition-shadow bg-white rounded-lg shadow-lg hover:shadow-md`}

  ${({ $featuredImage }) =>
    !!$featuredImage &&
    css`
      ${ImageWrapper} {
        background-image: url(${$featuredImage});
      }
    `}
`;

interface Props {
  fields: any[];
}
export const Service: React.FC<Props> = ({ fields = [], ...rest }) => {
  if (!fields) return null;
  const name = findByShortname(fields, 'title')?.content?.value ?? '';
  const slug = findByShortname(fields, 'slug')?.content?.value ?? '';
  const cover = findByShortname(fields, 'image')?.content?.value?.url ?? null;

  return (
    <CardWrapper href={`/service/${slug}`} $featuredImage={cover} {...rest}>
      <ImageWrapper src={cover} />
      <ContentWrapper>{!!name && <Title>{name}</Title>}</ContentWrapper>
    </CardWrapper>
  );
};
