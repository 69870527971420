import { ArrowCircleDownIcon, XIcon } from '@heroicons/react/outline';
import { useFields } from '@uidu/api.js/react';
import parse from 'html-react-parser';
import Image from 'next/image';
import React, { useState } from 'react';
import { Heading } from 'styles/styled';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { Button, Link, MaxWidthContent, RichText } from '../Base';

const Wrapper = styled.div`
  ${tw``}/*  transform: skew(-615deg); 
 */
`;
const ContentWrapper = styled.div`
  ${tw``}/*  transform: skew(615deg); 
 */
`;

const Text = tw.div`text-lg text-center pb-5 lg:(px-20) line-height[180%] px-5 text-white`;
const Label = tw.div`line-height[150%] text-center pb-5 lg:(px-60) px-5 text-white italic border-primary border-b`;
const StyledImage = styled(Image)`
  ${tw`rounded sr-only`}
  object-fit: cover;
  width: 100% !important;
  position: relative !important;
  height: 100% !important;
`;
const Title = tw.h2`font-bold text-secondary text-2xl lg:(text-2xl) ease-in-out`;

const ImageWrapper = styled.div`
  ${tw`relative z-10 min-height[300px] lg:min-height[500px] overflow-hidden rounded-lg col-start-5 col-span-6`}
  > div {
    position: unset !important;
  }
`;
const TitleWrapper = styled.div`
  ${tw`relative flex items-center justify-between transition duration-500 rounded-sm cursor-pointer text-secondary ease-in-out`}
`;
const AnswerWrapper = tw.p`pb-5 text-lg text-secondary`;

const ContentGrid = styled.div`
  ${tw`w-full flex space-y-4 flex-col lg:(grid grid-cols-10 space-y-0)`}
`;

const QuoteWrapper = tw.div`w-full bg-white shadow-lg text-gray-600 text-sm overflow-hidden flex flex-col justify-center bg-opacity-90 self-center relative z-20 rounded-lg lg:(-mr-36 row-span-full col-span-4 col-start-1 width[calc(100% + 8rem)])`;

const MenuButton = tw.button`p-2 flex justify-center rounded`;

const Menu = ({ menu, openMenu, setOpenMenu, index }) => {
  const title = findByShortname(menu.fields, 'titolo')?.content?.value ?? null;
  const description =
    findByShortname(menu.fields, 'descrizione')?.content?.value ?? null;
  const link = findByShortname(menu.fields, 'bottone')?.content ?? '';

  const { anchor } = useFields(menu.fields);

  return (
    <div tw="text-left p-4 lg:p-6 hover:(bg-white) first:rounded-t-lg last:rounded-b-lg border-b last:border-b-0 border-gray-300">
      <TitleWrapper
        onClick={() => setOpenMenu((prev) => (prev === index ? null : index))}
      >
        {!!title && <Title>{title}</Title>}
        <MenuButton>
          {openMenu === index ? (
            <XIcon tw="w-10 h-10 text-primary" />
          ) : (
            <ArrowCircleDownIcon tw="w-10 h-10 text-primary" />
          )}
        </MenuButton>
      </TitleWrapper>
      {openMenu === index ? (
        <AnswerWrapper>
          {!!description && (
            <RichText tw="line-clamp-6">{parse(description)}</RichText>
          )}
          {!!link && (
            <Button tw="mt-5">
              <Link
                href={`${link && link.value ? `${link?.value}${anchor}` : ''}`}
              >
                {link?.label || 'Scopri di più'}
              </Link>
            </Button>
          )}
        </AnswerWrapper>
      ) : null}
    </div>
  );
};

export const CommunityPreviewBlock = ({ fields }) => {
  const [openMenu, setOpenMenu] = useState<number | null>(null);
  const title = findByShortname(fields, 'titolo')?.content?.value ?? '';
  const description =
    findByShortname(fields, 'titoletto')?.content.value ?? null;
  const label = findByShortname(fields, 'label')?.content.value ?? null;
  const image =
    findByShortname(fields, 'immagine')?.content?.value?.url ?? null;
  const communities =
    findByShortname(fields, 'communities')?.content?.items ?? [];

  return (
    <div tw="relative overflow-hidden pt-10 pb-20">
      <div
        tw="bg-secondary absolute w-full h-full z-behind transform -skew-y-6 -top-1/2"
        style={{
          background: `linear-gradient(270deg, rgba(var(--brand-secondary), 1), rgba(var(--brand-secondary), 0.7)`,
        }}
      ></div>
      <Wrapper>
        <ContentWrapper>
          <MaxWidthContent tw="bg-transparent">
            {!!label && (
              <Label>
                <RichText>{parse(label)}</RichText>
              </Label>
            )}
            {!!title && <Heading tw="text-white">{title}</Heading>}
            {!!description && (
              <Text>
                <RichText>{parse(description)}</RichText>
              </Text>
            )}
            <ContentGrid tw="my-6">
              <QuoteWrapper>
                {communities.map((menu, index) => (
                  <Menu
                    menu={menu}
                    index={index}
                    openMenu={openMenu}
                    setOpenMenu={setOpenMenu}
                    key={`menu-${index}`}
                  />
                ))}
              </QuoteWrapper>
              <ImageWrapper>
                {!!image && (
                  <StyledImage
                    src={image}
                    alt={''}
                    layout="fill"
                    objectFit="cover"
                    // objectFit="scale-down"
                    // objectFit="none"
                    /* objectPosition={isInverted ? '0 0' : '100 0'} */
                  />
                )}
              </ImageWrapper>
            </ContentGrid>
          </MaxWidthContent>
        </ContentWrapper>
      </Wrapper>
    </div>
  );
};
