import tw, { css, styled } from 'twin.macro';
import { Link } from '..';
import { findByShortname } from '../../../utils/common';

const ContentWrapper = tw.div`flex-1 bg-white w-full text-center p-3 pb-6 md:p-6 flex flex-col justify-start border-t`;
const Title = tw.h3`text-lg font-semibold text-gray-800`;
const Avatar = tw.img`mx-auto object-cover bg-cover h-20 w-20 mb-4 -mt-14 border-2 border-white rounded-full lg:(w-24 h-24 -mt-20)`;
const ImageWrapper = styled.img`
  ${tw`flex-shrink-0 object-cover w-full h-24 bg-center bg-cover lg:h-28`}
`;
const CardWrapper = styled(Link)<{ $featuredImage: string }>`
  ${tw`w-full md:max-width[400px] flex flex-col rounded-lg shadow-lg hover:shadow-md transition-shadow overflow-hidden`}

  ${({ $featuredImage }) =>
    !!$featuredImage &&
    css`
      ${ImageWrapper} {
        background-image: url(${$featuredImage});
      }
    `}
`;

interface Props {
  fields: any[];
}
export const Organization: React.FC<Props> = ({ fields = [], ...rest }) => {
  if (!fields) return null;
  const name = findByShortname(fields, 'title')?.content?.value ?? '';
  const slug = findByShortname(fields, 'slug')?.content?.value ?? '';
  const cover = findByShortname(fields, 'image')?.content?.value?.url ?? null;

  return (
    <CardWrapper href={`/aziende${slug}`} $featuredImage={cover} {...rest}>
      <ImageWrapper src={cover} />
      <ContentWrapper>
        {/*         {!!cover && <Avatar src={cover} alt={name} />}
         */}{' '}
        {!!name && <Title>{name}</Title>}
      </ContentWrapper>
    </CardWrapper>
  );
};
