import { gql, useMutation } from '@apollo/client';
import Checkbox from '@uidu/checkbox';
import Field from '@uidu/field';
import FieldText from '@uidu/field-text';
import Form from '@uidu/form';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import tw from 'twin.macro';
import { data } from '../../data/footer.data';
import { findByShortname } from '../../utils/common';
import { Button, Link, MaxWidthContent } from '../Base';

const CREATE_LIST_SUBSCRIPTION = gql`
  # Increments a back-end counter and gets its resulting value
  mutation CreateListSubscriptionMutation(
    $input: CreateListSubscriptionInput!
  ) {
    createListSubscription(input: $input) {
      errors {
        key
        name
      }
      listSubscription {
        id
        token
      }
    }
  }
`;

export const Footer = () => {
  const form = useRef(null);

  const brandLogo = findByShortname(data.blocks, 'brand-logo');
  const regioneLogo = findByShortname(data.blocks, 'regione-logo');
  const valserianaLogo = findByShortname(data.blocks, 'valseriana-logo');
  const socials = findByShortname(data.blocks, 'socials').fields;

  const formFooter = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const [listSubscription, setListSubscription] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (formFooter.current) {
      setLoaded(true);
    }
  }, [formFooter]);

  const [mutate, { data: mutationData, loading, error }] = useMutation(
    CREATE_LIST_SUBSCRIPTION,
    {
      onCompleted: ({
        createListSubscription: { listSubscription, errors },
      }) => {
        if (errors.length) {
          setErrors(errors);
          form.current?.reset({});
          setTimeout(() => {
            setErrors(null);
          }, 3000);
        } else {
          form.current?.reset({});
          setListSubscription(listSubscription);
          setTimeout(() => {
            setListSubscription(null);
          }, 3000);
        }
      },
    },
  );

  const handleSubmit = async ({ privacy, ...model }) => {
    setErrors(null);
    setListSubscription(null);

    return mutate({
      variables: { input: { attributes: { ...model } } },
    });
    // return CreateListSubscription({ input: { attributes: { ...model } } })
    //   .then((response) => {
    //     form.current?.reset(model);
    //     setListSubscription(response);
    //     setTimeout(() => {
    //       setListSubscription(null);
    //     }, 3000);
    //   })
    //   .catch((response) => {
    //     setErrors(response);
    //     form.current?.reset(model);
    //     setTimeout(() => {
    //       setErrors(null);
    //     }, 3000);
    //   });
  };

  return (
    <footer tw="mt-auto border-t">
      <MaxWidthContent tw="py-10 lg:flex lg:items-center lg:justify-between xl:mt-0 space-y-6 lg:(space-x-14 space-y-0)">
        <div tw="lg:max-width[50%]">
          <h3 tw="text-base font-semibold tracking-wider uppercase">
            <strong>Iscriviti</strong> alla nostra newsletter
          </h3>
          <p tw="mt-2 text-lg text-gray-500">
            I servizi, le ultime interviste e le opportunità del territorio
            della Val Seriana. Inserisci la tua email per ricevere le notizie.
          </p>
        </div>
        <div tw="flex flex-grow flex-col">
          <div tw="flex-grow flex">
            <Form
              ref={form}
              id="newsletter-form"
              tw="flex-grow"
              layout="elementOnly"
              handleSubmit={handleSubmit}
              footerRenderer={({ loading, canSubmit }) =>
                loaded &&
                createPortal(
                  <Button
                    tabIndex={-1}
                    css={[
                      tw`flex items-center justify-center w-full h-full text-white bg-primary`,
                      loading &&
                        tw`cursor-not-allowed pointer-events-none bg-amber-50`,
                      !canSubmit &&
                        tw`bg-opacity-50 cursor-not-allowed pointer-events-none`,
                    ]}
                    disabled={loading}
                    type="submit"
                    form="newsletter-form"
                  >
                    Iscriviti
                  </Button>,
                  formFooter.current,
                )
              }
            >
              <FieldText
                type="hidden"
                name="listId"
                value={process.env.NEXT_PUBLIC_LIST_ID}
              />
              <div tw="space-y-4">
                <div tw="flex">
                  <Field
                    placeholder="Email*"
                    kind="email"
                    name="contactAttributes[email]"
                    required
                  />
                  <div
                    tw="rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0"
                    ref={formFooter}
                  />
                </div>
                <Checkbox
                  name="privacy"
                  label={
                    <>
                      Ho letto e accettato l’
                      <Link href="privacy" tw="underline! hover:text-primary!">
                        informativa sulla privacy
                      </Link>
                      .*
                    </>
                  }
                  layout="elementOnly"
                  required
                ></Checkbox>
              </div>
            </Form>
          </div>
          {listSubscription && (
            <div tw="my-6 text-green-500 font-bold">
              Grazie per la tua iscrizione!
            </div>
          )}
          {errors && (
            <div tw="my-6 text-red-500 font-bold">
              {errors.map((e) => e.name).join(', ')}
            </div>
          )}
        </div>
      </MaxWidthContent>
      <div tw="border-t border-dashed">
        <MaxWidthContent>
          <div tw="grid grid-cols-3 md:py-8 gap-8">
            <div tw="flex justify-center items-center">
              <div tw="w-full h-32 relative">
                <Image
                  src={findByShortname(regioneLogo.fields, 'logo').content.src}
                  alt={
                    findByShortname(regioneLogo.fields, 'logo').content.altText
                  }
                  layout="fill"
                  objectFit="contain"
                />
                <Link
                  tw="inset-0 absolute"
                  href="https://www.regione.lombardia.it/wps/portal/istituzionale/HP/servizi-e-informazioni/cittadini/persone-casa-famiglia/conciliazione-vita-lavoro"
                ></Link>
              </div>
            </div>
            <div tw="flex justify-center items-center">
              <div tw="w-full h-16 relative">
                <Image
                  src={findByShortname(brandLogo.fields, 'logo').content.src}
                  alt="Welfare Beatrice"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
            <div tw="flex justify-center items-center">
              <div tw="w-full h-20 relative">
                <Image
                  src={
                    findByShortname(valserianaLogo.fields, 'logo').content.src
                  }
                  alt={
                    findByShortname(valserianaLogo.fields, 'logo').content
                      .altText
                  }
                  layout="fill"
                  objectFit="contain"
                />
                <Link
                  tw="inset-0 absolute"
                  href="https://www.ssvalseriana.org/societa/home/index.html"
                ></Link>
              </div>
            </div>
          </div>
        </MaxWidthContent>
      </div>
      <div tw="py-6 bg-gray-800 bottom-0">
        <p tw="text-sm text-white text-center">
          All rights reserved © COPYRIGHTS 2021 - SERVIZI SOCIOSANITARI
          VALSERIANA SRL
        </p>
        <div tw="space-x-2.5 text-primary text-sm text-center mt-2">
          <Link href="/privacy" tw="hover:underline">
            Privacy Policy
          </Link>
          <Link href="/privacy" tw="hover:underline">
            - Cookie Policy
          </Link>
          <Link href="/faq" tw="hover:underline">
            - FAQ
          </Link>
        </div>
      </div>
    </footer>
  );
};
