import parse from 'html-react-parser';
import Image from 'next/image';
import { useRouter } from 'next/router';
import tw, { styled } from 'twin.macro';
import { Heading, SectionWrapper } from '../../styles/styled';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const TextWrapper = styled.div`
  ${tw`py-3 lg:(py-10) text-gray-800`}
`;
const ImageWrapper = styled.div`
  ${tw`order-1 relative min-height[300px] lg:(order-none min-height[400px])`}
`;
const StyledImage = styled(Image)`
  ${tw`w-full! h-auto`}
  img {
    ${tw`w-full! h-auto!`}
  }
`;
const Paragraph = styled.div`
  ${tw`line-height[140%] text-base lg:text-lg`}
`;

export const ImageParagraphBlock = ({ fields }) => {
  const router = useRouter();
  const heading = findByShortname(fields, 'titolo')?.content.value ?? null;
  const paragraph = findByShortname(fields, 'paragraph')?.content.value ?? null;
  const image = findByShortname(fields, 'image')?.content?.value?.url ?? null;
  const isInverted =
    findByShortname(fields, 'isInverted')?.content?.value ?? false;
  const isBlog = router?.pathname?.includes('aziende');

  return (
    <SectionWrapper>
      <MaxWidthContent>
        <div tw="grid grid-cols-1 gap-4 md:(grid-cols-2 gap-8 my-16)">
          <ImageWrapper>
            {!!image && (
              <StyledImage
                src={image}
                alt={''}
                layout="fill"
                objectFit="contain"
                // objectFit="scale-down"
                // objectFit="none"
                /* objectPosition={isInverted ? '0 0' : '100 0'} */
              />
            )}
          </ImageWrapper>
          <TextWrapper>
            {!!heading && (
              <Heading tw="text-left mb-5">{parse(heading)}</Heading>
            )}
            {!!paragraph && (
              <Paragraph>
                <RichText tw="prose-lg lg:prose-lg">
                  {parse(paragraph)}
                </RichText>
              </Paragraph>
            )}
          </TextWrapper>
        </div>
      </MaxWidthContent>
    </SectionWrapper>
  );
};
