import parse from 'html-react-parser';
import Image from 'next/image';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const Paragraph = styled.div`
  ${tw`line-height[160%] text-gray-800 text-base col-span-full lg:(col-span-2 text-lg)`}
`;
const ImageWrapper = styled.div`
  ${tw`relative min-height[140px] col-span-1 lg:col-span-2`}
`;
const ContentGrid = styled.div`
  ${tw`relative w-full grid grid-cols-1 lg:(mb-20 py-10 grid-cols-4 gap-10)`}
`;
const StyledImage = styled(Image)`
  ${tw`h-auto `}
  img {
    ${tw` h-auto!`}
  }
`;

const Heading = tw.h2`text-left uppercase text-primary font-medium text-lg lg:text-3xl mb-3`;

export const DescriptionBlock = ({ fields }) => {
  const title = findByShortname(fields, 'titolo')?.content?.value ?? '';
  const text = findByShortname(fields, 'paragrafo')?.content?.value ?? '';
  const image = findByShortname(fields, 'logo')?.content?.value?.url ?? null;
  return (
    <MaxWidthContent tw="max-w-5xl">
      <ContentGrid tw="items-center">
        <ImageWrapper>
          {!!image && (
            <StyledImage
              src={image}
              alt={''}
              layout="fill"
              objectFit="contain"
              // objectFit="scale-down"
              // objectFit="none"
              /* objectPosition={isInverted ? '0 0' : '100 0'} */
            />
          )}
        </ImageWrapper>
        <div tw="lg:col-span-2">
          {!!title && <Heading>{title}</Heading>}
          {!!text && (
            <Paragraph>
              <RichText>{parse(text)}</RichText>
            </Paragraph>
          )}
        </div>
      </ContentGrid>
    </MaxWidthContent>
  );
};
