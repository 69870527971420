import { useFields } from '@uidu/api.js/react';
import parse from 'html-react-parser';
import { PaintingLabel } from 'styles/styled';
import tw, { css, styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText, SliderHome } from '../Base';

const SlideWrapper = tw.div`w-5/6 relative h-auto mb-0 line-height[100%]`;
const InfoItem = tw.div`bg-white p-2.5 w-1/4 ring-1 lg:(rounded-full) rounded-lg lg:(mx-5) mx-2 cursor-pointer flex `;

const HeroWrapper = styled.div<{ bgImage: string }>`
  ${tw`w-full bg-gray-400 text-white h-96 flex flex-col justify-center items-end lg:(height[512px]) relative overflow-hidden bg-cover bg-center`}
  ${({ bgImage }) =>
    !!bgImage &&
    css`
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
        url(${bgImage});
    `}
`;
const Wrapper = tw.div`mt-10 w-full py-5 justify-center flex bottom-0 bg-secondary`;
const H1 = tw.h1`leading-none w-full text-lg mb-3 lg:(text-5xl lg:mb-5)`;
const Reference = styled.div`
  ${tw`absolute px-2 py-1 text-xs text-white rounded-lg top-1 right-1 lg:text-sm bg-secondary`}
`;
const Slide = ({ slide }) => {
  const header =
    findByShortname(slide.fields, 'titolo')?.content?.value ?? null;
  const link = findByShortname(slide.fields, 'link')?.content ?? '';

  return (
    <SlideWrapper>
      {!!header && (
        <RichText tw="w-full mb-3 text-2xl md:text-4xl lg:text-5xl line-height[100%]! block!">
          {parse(header)}
        </RichText>
      )}
      {/* {!!link && (
        <Button>
          <Link href={link?.url || link?.value || ''}>
            {link?.label || 'Scopri di più'}
          </Link>
        </Button>
      )} */}
    </SlideWrapper>
  );
};
export const HeroBlockHome = ({ name, shortname, fields }) => {
  const {
    titoli: headers,
    cover: bgImage,
    titolo: title,
    etichetta: reference,
  } = useFields(fields);

  const slides = headers?.map((item, i) => (
    <Slide key={`quote-${i}`} slide={item} />
  ));

  return (
    <HeroWrapper bgImage={bgImage?.url ?? ''}>
      <MaxWidthContent tw="h-full">
        {!!title && <H1>{title}</H1>}
        <SliderHome
          // feed slides as array of components
          autoplay
          slides={slides}
          loop
          hasPagination
          slidesPerView={1}
          breakpoints={{
            0: { spaceBetween: 10 },
            480: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 1, spaceBetween: 40 },
          }}
        />
      </MaxWidthContent>
      {!!reference && <PaintingLabel>{parse(reference)}</PaintingLabel>}
      {/* <div tw="absolute bottom-0 w-full">
      <Wrapper>
      <InfoItem>
          <div tw="min-width[20px] mr-2.5 text-primary">
            <SearchIcon tw="w-5 h-5" />
          </div>
          <p tw="text-gray-500 lg:text-base text-sm">{searchService}</p>
        </InfoItem>
        <InfoItem>
          <div tw="lg:(min-width[20px] mr-2.5) text-primary">
            <LocationMarkerIcon tw="w-5 h-5" />
          </div>
          <p tw="text-gray-500 lg:text-base text-sm">{searchLocation}</p>
        </InfoItem>
        <Button tw="uppercase mx-5">
          <p>{button}</p>
        </Button>
      </Wrapper>
      </div> */}
    </HeroWrapper>
  );
};
