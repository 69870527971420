import { Link, MaxWidthContent } from 'components/Base';
import tw, { css, styled } from 'twin.macro';
import { findByShortname } from 'utils/common';

const ContentWrapper = tw.div`flex-1 bg-white w-full text-center p-3 pb-6 md:p-6 flex flex-col justify-start`;

const ContentGrid = styled.div`
  ${tw`relative w-full grid grid-cols-1 pb-5 gap-6 md:(grid-cols-2) lg:(py-10 mb-10 grid-cols-3 gap-10)`}
`;
const TextWrapper = tw.h3`mt-2.5 p-5 text-lg text-center text-secondary font-semibold`;

const ImageWrapper = styled.div<{ bgImage: string }>`
  ${tw`relative flex-shrink-0 overflow-hidden bg-center bg-no-repeat bg-contain border-b`}
  height: 10rem !important;
  > div {
    position: unset !important;
  }
  ${({ bgImage }) =>
    !!bgImage &&
    css`
      background-image: url(${bgImage});
    `}
`;

const Card = ({ card }) => {
  const cardLink = findByShortname(card.fields, 'slug')?.content?.value ?? null;
  const cardTitle =
    findByShortname(card.fields, 'nome')?.content?.value ?? null;
  const cardCover =
    findByShortname(card.fields, 'cover')?.content?.value?.url ?? null;

  return (
    <div tw="shadow-lg rounded-lg border border-gray-200">
      <Link
        href={`aziende/${cardLink}` ?? ''}
        tw="w-full relative transform transition-transform hover:(-translate-y-5)"
      >
        <ImageWrapper tw="rounded-lg" bgImage={cardCover}></ImageWrapper>
      </Link>
      <TextWrapper>{cardTitle}</TextWrapper>
    </div>
  );
};
export const AziendeBlock = ({ fields }) => {
  const cards = findByShortname(fields, 'aziende-list')?.content?.items ?? [];

  return (
    <MaxWidthContent>
      <ContentGrid tw="my-8">
        {cards.map((card, index) => (
          <Card card={card} key={`card-${index}`} />
        ))}
      </ContentGrid>
    </MaxWidthContent>
  );
};
