import { LocationMarkerIcon, SearchIcon } from '@heroicons/react/outline';
import tw from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { Button, MaxWidthContent } from '../Base';

const Heading = tw.h2`font-bold text-center text-lg lg:text-2xl mb-3 lg:mb-5`;
const Wrapper = tw.div`justify-center flex flex-col mb-10 space-y-3 md:(flex-row space-x-4 space-y-0)`;
const InfoItem = tw.div`p-2.5 flex-grow ring-1 rounded-lg cursor-pointer flex`;

export const SearchBlock = ({ fields }) => {
  const title = findByShortname(fields, 'title')?.content?.value ?? '';
  const searchService =
    findByShortname(fields, 'search1')?.content?.value ?? '';
  const searchLocation =
    findByShortname(fields, 'search2')?.content?.value ?? '';
  const button = findByShortname(fields, 'button')?.content?.value ?? '';

  return (
    <MaxWidthContent tw="mt-5 lg:mt-10">
      {!!title && <Heading>{title}</Heading>}
      <Wrapper>
        <InfoItem>
          <div tw="min-width[20px] mr-2.5">
            <SearchIcon tw="w-5 h-5" />
          </div>
          <p tw="text-gray-300 lg:text-base text-sm">{searchService}</p>
        </InfoItem>
        <InfoItem>
          <div tw="lg:(min-width[20px] mr-2.5) ">
            <LocationMarkerIcon tw="w-5 h-5" />
          </div>
          <p tw="text-gray-300 lg:text-base text-sm">{searchLocation}</p>
        </InfoItem>
        <Button tw="uppercase" color="secondary">
          <p>{button}</p>
        </Button>
      </Wrapper>
    </MaxWidthContent>
  );
};
