import React from 'react';
import tw from 'twin.macro';

export default function BotMessageOption({
  children,
  isSelected = false,
  onReset,
  ...rest
}) {
  return (
    <>
      <button
        type="button"
        css={[
          isSelected
            ? tw`bg-gray-200 max-width[60%] border-gray-300`
            : tw`border-primary `,
          tw`border text-left rounded-2xl max-width[90%] px-4 py-2 font-medium bg-white hover:(bg-primary text-white cursor-pointer)`,
        ]}
        {...rest}
      >
        {children}
      </button>
      {isSelected && onReset && (
        <button tw="text-sm mt-2!" onClick={onReset}>
          Modifica
        </button>
      )}
    </>
  );
}
