import { ArrowCircleUpIcon, XIcon } from '@heroicons/react/outline';
import { useFields } from '@uidu/api.js/react';
import UiduButton from '@uidu/button';
import { motion, useMotionValue } from 'framer-motion';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { Heading, Subheading } from 'styles/styled';
import tw, { css, styled } from 'twin.macro';
import { Link, MaxWidthContent, RichText } from '../Base';

export const openSpring = { type: 'spring', stiffness: 200, damping: 30 };
export const closeSpring = { type: 'spring', stiffness: 300, damping: 35 };

const CardWrapper = styled.div<{ $featuredImage: string }>`
  ${tw`flex flex-col w-full overflow-hidden transition-shadow bg-white rounded shadow cursor-pointer hover:shadow-md`}

  ${({ $featuredImage }) =>
    !!$featuredImage &&
    css`
      img {
        background-image: url(${$featuredImage});
      }
    `}
`;

const Overlay = ({ isSelected, onClose }) => (
  <motion.div
    initial={false}
    animate={{ opacity: isSelected ? 1 : 0 }}
    transition={{ duration: 0.2 }}
    style={{ pointerEvents: isSelected ? 'auto' : 'none' }}
    tw="z-60 fixed bg-gray-900 bg-opacity-30 will-change[opacity] top-0 bottom-0 left[50%] transform -translate-x-1/2 w-full"
  >
    <button onClick={onClose} tw="inset-0 w-full h-full"></button>
  </motion.div>
);

function Macroarea({ macroarea, needs, isSelected, handleCategory, onClose }) {
  const y = useMotionValue(0);
  const zIndex = useMotionValue(isSelected ? 2 : 0);
  const cardRef = useRef(null);

  console.log('needs', needs);

  const filteredSolutions = needs.reduce((arr, item) => {
    if (item.fieldValues.macroaree.map((m) => m.id).includes(macroarea.id)) {
      if (item.fieldValues.soluzioni) {
        const concatArr = Array.prototype.concat.apply(
          item.fieldValues.soluzioni,
          arr,
        );
        return concatArr;
      }
      return arr;
    }
    return arr;
  }, []);
  const filteredSolutionIds = filteredSolutions.map((s) => s.id);

  console.log('filteredSolutions', filteredSolutions);

  function checkZIndex(latest) {
    if (isSelected) {
      zIndex.set(2);
    } else if (!isSelected && latest.scaleX < 1.01) {
      zIndex.set(0);
    }
  }

  return (
    <CardWrapper key={macroarea.id} onClick={() => handleCategory(macroarea)}>
      <Overlay isSelected={isSelected} onClose={onClose} />
      <div
        css={[
          tw`relative block w-full h-full`,
          isSelected &&
            tw`fixed top-0 bottom-0 left[50%] transform -translate-x-1/2 w-full overflow-auto z-80 overscroll-contain`,
        ]}
      >
        <motion.div
          ref={cardRef}
          css={[isSelected && tw`max-w-5xl mx-auto my-8 bg-white rounded`]}
          style={{ zIndex, y }}
          layoutTransition={isSelected ? openSpring : closeSpring}
          drag={isSelected ? 'y' : false}
          // dragConstraints={constraints}
          // onDrag={checkSwipeToDismiss}
          onUpdate={checkZIndex}
        >
          <div tw="relative">
            {isSelected && (
              <UiduButton
                onClick={onClose}
                tw="absolute right-4 top-4 z-20 cursor-pointer"
                iconBefore={<XIcon tw="h-5 w-5" />}
              >
                Chiudi
              </UiduButton>
            )}
            <motion.img
              css={[
                tw`flex-shrink-0 object-cover w-full bg-center bg-cover`,
                isSelected ? tw`h-96` : tw`h-40 md:h-24 lg:h-48`,
              ]}
              animate={
                isSelected
                  ? { x: 0, y: 0, height: 300 }
                  : { x: 0, y: 0, height: 200 }
              }
              transition={closeSpring}
              initial={false}
              src={macroarea.fieldValues?.cover?.url}
            />
            <motion.h3
              tw="absolute top-0 bottom-0 w-full text-white font-bold max-w-prose flex items-end backdrop-brightness-75"
              animate={
                isSelected
                  ? {
                      fontSize: '1.75rem',
                      lineHeight: '1.75rem',
                      padding: '2rem',
                    }
                  : {
                      fontSize: '1.25rem',
                      lineHeight: '1.25rem',
                      padding: '1rem',
                    }
              }
            >
              {macroarea.name}
            </motion.h3>
          </div>
          {isSelected && (
            <div tw="p-6">
              <div tw="grid grid-cols-1 md:grid-cols-1 gap-6">
                {filteredSolutions
                  .filter(
                    (item, index) =>
                      filteredSolutionIds.indexOf(item.id) === index,
                  )
                  .map((solution) => (
                    <Link
                      href={`/scopri?soluzioni_ids=${solution.id}`}
                      key={solution.id}
                      tw="border rounded p-4 block text-lg font-semibold"
                    >
                      {solution.name}
                    </Link>
                  ))}
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </CardWrapper>
  );
}

export const ServiziBlock = ({ fields, macroareas, needs }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const router = useRouter();
  const fromBot = router.asPath == '/#servizi';

  const {
    titolo: title,
    sottotitolo: description,
    'a-ncora-sezione': anchor,
  } = useFields(fields);

  const handleCategory = (model) => {
    setSelectedItem(model.id);
  };

  const onClose = (e) => {
    e.stopPropagation();
    setSelectedItem(null);
  };

  return (
    <div tw="relative overflow-hidden pt-10 pb-20">
      <div tw="absolute top-0 -mt-20" id="servizi" />
      <div
        tw="bg-primary absolute w-full h-full z-behind transform -skew-y-6 -top-1/3"
        style={{
          background: `linear-gradient(270deg, rgba(var(--brand-primary), 1), rgba(var(--brand-primary), 0.7)`,
        }}
      ></div>
      <MaxWidthContent>
        {fromBot && (
          <div tw="text-center flex-col space-y-2 items-center justify-center mb-5">
            <div tw="flex justify-center">
              <ArrowCircleUpIcon tw="h-5 w-5 text-white" />
            </div>
            <div>
              <Link href="#bot" tw="underline text-sm text-white">
                Torna indietro
              </Link>
            </div>
          </div>
        )}
        {!!title && <Heading tw="text-white">{title}</Heading>}
        {!!description && (
          <Subheading tw="text-white">
            <RichText>{parse(description)}</RichText>
          </Subheading>
        )}
        <div tw="grid grid-cols-2 my-6 gap-4 md:(grid-cols-3 gap-8 my-6)">
          {(macroareas || []).map((macroarea, index) => (
            <Macroarea
              needs={needs}
              key={macroarea.id}
              isSelected={selectedItem === macroarea.id}
              macroarea={macroarea}
              handleCategory={handleCategory}
              onClose={onClose}
            />
          ))}
        </div>
      </MaxWidthContent>
    </div>
  );
};
