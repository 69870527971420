import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import tw, { styled } from "twin.macro";
import { data as signupNavigation } from "../../data/signupNavigation.data";
import { findByShortname } from "../../utils/common";
import { MaxWidthContent, Link, Button } from "../Base";

const NavWrapper = tw.div`w-full h-16 lg:h-20 fixed left-0 right-0 top-0 z-50 flex justify-center bg-white shadow-md`;
const NavContent = tw(
  MaxWidthContent
)`w-full flex items-center justify-between`;
const NavItems = tw.nav`flex items-center space-x-3 md:space-x-4 xl:space-x-4`;
const NavLink = styled(Link)<{ $isActive?: boolean }>`
  ${tw`p-2 font-thin whitespace-nowrap`}

  ${({ $isActive }) => $isActive && tw`font-bold bg-gray-100 rounded-xl`}
`;
const DesktopNavWrapper = tw.div`hidden lg:flex items-center`;
const MobileNavWrapper = tw.div`flex lg:hidden items-center`;
const MobileMenuButton = tw.button`p-8 flex items-center justify-center rounded`;
const MobileMenuWrapper = tw.div`fixed z-50 bottom-0 top-12 py-5 w-screen height[100vh - 60px] flex flex-col bg-white text-black`;

export const SignupNavigation = ({ navigation }) => {
  const router = useRouter();
  const brandLogo = findByShortname(signupNavigation.blocks, "brand-logo");
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  return (
    <>
      <NavWrapper>
        <NavContent>
          <div tw="min-width[150px]">
            <Link href="/">
              <div tw="w-full h-2" />
              <Image
                width={133}
                height={33}
                src={findByShortname(brandLogo.fields, "logo").content.src}
                alt={findByShortname(brandLogo.fields, "logo").content.altText}
                tw="w-full h-16"
              />
            </Link>
          </div>
          <DesktopNavWrapper>
            <NavItems>
              {!!navigation.fields &&
                navigation.fields?.map((link) => (
                  <NavLink
                    href={link?.content?.url}
                    key={link?.shortname}
                    $isActive={router.asPath === link.content.url}
                  >
                    {link?.content?.label}
                  </NavLink>
                ))}
            </NavItems>
          </DesktopNavWrapper>
          <MobileNavWrapper>
            <MobileMenuButton onClick={() => setMenuIsOpen((prev) => !prev)}>
              {menuIsOpen ? (
                <XIcon tw="w-6 h-6 text-black" />
              ) : (
                <MenuIcon tw="w-6 h-6  text-black" />
              )}
            </MobileMenuButton>
          </MobileNavWrapper>
        </NavContent>
      </NavWrapper>
      {menuIsOpen && (
        <MobileMenuWrapper>
          <MaxWidthContent tw="flex flex-col">
            {!!navigation.fields &&
              navigation.fields?.map((link) => (
                <NavLink
                  href={link?.content?.url}
                  key={link?.shortname}
                  $isActive={router.asPath.includes(link.content.url)}
                >
                  {link?.content?.label}
                </NavLink>
              ))}
          </MaxWidthContent>
        </MobileMenuWrapper>
      )}
    </>
  );
};
