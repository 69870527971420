import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/outline';
import parse from 'html-react-parser';
import Image from 'next/image';
import tw, { styled } from 'twin.macro';
import { findByShortname, useFields } from '../../utils/common';
import { Button, Link, MaxWidthContent } from '../Base';

const Heading = tw.h2`font-bold text-lg pt-5 lg:text-3xl mb-2.5 sm:mb-10 lg:mb-5`;
const ContentGrid = tw.div`relative w-full grid grid-cols-1 column-gap[100px] pt-5 pb-2.5 lg:(pb-4 pt-10 grid-cols-5 column-gap[40px])`;
const InfoItem = tw.div`w-full flex py-2.5`;
const StyledImage = styled(Image)`
  ${tw`h-auto `}
  img {
    ${tw` h-auto!`}
  }
`;
const InfoWrapper = tw.div`col-span-full lg:(col-start-6 col-span-7)`;
const ImageWrapper = styled.div`
  ${tw`relative flex`}
`;

export const HeaderBlock = ({ fields }) => {
  const {
    titolo: title,
    indirizzo: address,
    'link-indirizzo': addressLink,
    telefono: tel,
    email: email,
    referente: user,
    cooperativa: coop,
    comune: info,
    bottone: button,
  } = useFields(fields);
  const image =
    findByShortname(fields, 'immagine')?.content?.value?.url ?? null;

  return (
    <div tw="flex">
      <ImageWrapper>
        {!!image && (
          <StyledImage
            src={image}
            alt={''}
            layout="fill"
            objectFit="contain"
            // objectFit="scale-down"
            // objectFit="none"
            /* objectPosition={isInverted ? '0 0' : '100 0'} */
          />
        )}
      </ImageWrapper>
      <MaxWidthContent tw="flex">
        <InfoWrapper>
          {!!title && <Heading tw="col-start-5 col-span-2">{title}</Heading>}
          {!!coop && <InfoItem tw="font-bold text-lg">{coop}</InfoItem>}
          {!!info && <InfoItem tw="font-bold text-lg">{info}</InfoItem>}
          {!!address && (
            <InfoItem>
              <div tw="min-width[20px] mr-2.5">
                <LocationMarkerIcon tw="w-5 h-5" />
              </div>
              <Link
                href={addressLink ?? ''}
                target="_blank"
                tw="flex-1 hover:underline"
              >
                {parse(address)}
              </Link>
            </InfoItem>
          )}
          {!!tel && (
            <InfoItem>
              <div tw="min-width[20px] mr-2.5">
                <PhoneIcon tw="w-5 h-5" />
              </div>
              <Link
                href={`tel:+39${tel.replace(/ /g, '')}`}
                tw="flex-1 hover:underline"
              >
                {tel}
              </Link>
            </InfoItem>
          )}
          {!!email && (
            <InfoItem>
              <div tw="min-width[20px] mr-2.5">
                <MailIcon tw="w-5 h-5" />
              </div>
              <Link
                href={`mailto:${email.replace(/ /g, '')}`}
                tw="flex-1 hover:underline"
              >
                {email}
              </Link>
            </InfoItem>
          )}
          {!!user && (
            <InfoItem>
              <div tw="min-width[20px] mr-2.5">
                <UserIcon tw="w-5 h-5" />
              </div>
              {user}
            </InfoItem>
          )}
          <Button tw="uppercase mx-5 rounded-lg my-2.5">
            <p>{button}</p>
          </Button>
        </InfoWrapper>
      </MaxWidthContent>
    </div>
  );
};
