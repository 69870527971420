import { gql, useQuery } from '@apollo/client';
import Avatar from '@uidu/avatar';
import {
  ButtonItem,
  CustomItem,
  LinkItem,
  MenuGroup,
  Section,
} from '@uidu/menu';
import Popup from '@uidu/popup';
import { useApollo } from 'lib/apollo';
import { signOut } from 'next-auth/react';
import React, { useState } from 'react';
import { Link } from '../Base';

export default function UserDropdown({ session }) {
  const [isOpen, setIsOpen] = useState(false);
  const client = useApollo({}, { session });
  const { data, loading, error } = useQuery(
    gql`
      query currentAccountQuery {
        currentAccount {
          id
          email
          avatar
        }
      }
    `,
    { variables: {}, client },
  );

  return (
    <Popup
      isOpen={isOpen}
      placement="bottom-end"
      trigger={(triggerProps) => (
        <button
          {...triggerProps}
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        >
          <Avatar src={data?.currentAccount?.avatar} size="medium"></Avatar>
        </button>
      )}
      onClose={() => setIsOpen(false)}
      content={() => (
        <div>
          <MenuGroup>
            <Section>
              <ButtonItem tw="">Il tuo profilo</ButtonItem>
              <LinkItem href="https://welfarebeatrice.uidu.org/saml/initiate">
                Vai al tuo piano Welfare
              </LinkItem>
              <CustomItem
                component={(props) => (
                  <Link href="/community/demo" {...props} tw="">
                    La community
                  </Link>
                )}
              ></CustomItem>
              <ButtonItem tw="">Privacy</ButtonItem>
            </Section>
            <Section hasSeparator>
              <ButtonItem onClick={signOut} tw="">
                Logout
              </ButtonItem>
            </Section>
          </MenuGroup>
        </div>
      )}
    ></Popup>
  );
}
