import { gql, useQuery } from '@apollo/client';
import { XIcon } from '@heroicons/react/outline';
import { useForm } from '@uidu/api.js/react';
import UiduButton from '@uidu/button';
import BotSeeMore from 'components/Custom/BotSeeMore';
import { motion, useAnimation } from 'framer-motion';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import tw from 'twin.macro';
import { Heading, SectionWrapper, Subheading } from '../../styles/styled';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';
import BotMessage from '../Custom/BotMessage';
import BotMessageGroup from '../Custom/BotMessageGroup';
import BotMessageOption from '../Custom/BotMessageOption';
import BotResponse from '../Custom/BotResponse';
// import patternBg from './memphis-mini.png';

const Wrapper = tw.div`w-full min-height[300px] bg-gray-50`;

const Backdrop = tw(motion.button)`fixed bg-transparent z-20 inset-0 m-auto`;

export const BotBlock = ({ fields }) => {
  const scroller = useRef(null);
  const chatbotRef = useRef(null);
  const router = useRouter();
  const [initiated, setInitiated] = useState(false);
  const introControls = useAnimation();
  const conversationControls = useAnimation();
  const infoControls = useAnimation();

  const [answers, setAnswers] = useState({});
  const [formResponse, setFormResponse] = useState(null);
  // { questionId: { questionStatus: status, optionsStatus: status}}

  const header = findByShortname(fields, 'titolo')?.content?.value ?? null;
  const image =
    findByShortname(fields, 'immagine')?.content?.value?.url ?? null;
  const paragraph = findByShortname(fields, 'paragraph')?.content.value ?? null;
  const description =
    findByShortname(fields, 'description')?.content.value ?? null;
  const form = findByShortname(fields, 'form')?.linkedRecord ?? null;

  const { createFormResponse, updateFormResponse } = useForm({
    form,
  });

  const { data, loading, error, refetch } = useQuery(
    gql`
      query searchQuery($formId: ID!, $fieldValues: JSON!) {
        currentWorkspace {
          form(id: $formId) {
            id
            name
            preferences
            formQuestions(first: 100) @connection(key: "Form_formQuestions") {
              edges {
                node {
                  id
                  label
                  hint
                  placeholder
                  required
                  preferences
                  rules
                  field {
                    id
                    shortname
                    name
                    kind
                    preferences
                    modelItems(fieldValues: $fieldValues) {
                      id
                      name
                    }
                    linkedModel {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { variables: { formId: form.id, fieldValues: {} } },
  );

  console.log('data', data);
  console.log('error', error);

  const extendedForm = data?.currentWorkspace?.form;

  const formQuestions = extendedForm?.formQuestions.edges.map(
    (edge) => edge.node,
  );

  const [finalChoice, setFinalChoice] = useState(null);

  const [formState, setFormState] = useState(
    formQuestions
      ? formQuestions.reduce((acc, curr, index) => {
          if (index === 0) {
            acc[curr.id] = {
              questionStatus: 'isTyping',
            };
          } else {
            acc[curr.id] = {};
          }
          return acc;
        }, {})
      : {},
  );

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const initiate = () => {
    setInitiated(true);
    try {
      createFormResponse(process.env.NEXT_PUBLIC_API_ENDPOINT, {
        input: {
          attributes: {
            formId: form.id,
          },
        },
      }).then((response) =>
        setFormResponse(response.createFormResponse.formResponse),
      );
    } catch (error) {
      console.log(error);
    }
    delay(10)
      .then(() => {
        if (!loading) {
          setFormState((prevState) => ({
            ...prevState,
            [formQuestions[0].id]: {
              questionStatus: 'isTyping',
              optionsStatus: 'isTyping',
            },
          }));
        }
      })
      .then(() => {
        return delay(600).then(() => {
          if (!loading) {
            setFormState((prevState) => ({
              ...prevState,
              [formQuestions[0].id]: {
                questionStatus: 'isVisible',
                optionsStatus: 'isVisible',
              },
            }));
          }
        });
      });
  };

  // this should set formResponse as completed
  const completeWith = async (input, option) => {
    if (formResponse) {
      try {
        updateFormResponse(process.env.NEXT_PUBLIC_API_ENDPOINT, {
          input: {
            id: formResponse.id,
            event: 'complete!',
            attributes: {
              fieldValuesAttributes: [
                {
                  fieldId: input.field.id,
                  content: { value: option.id },
                  linkedRecordId: option.id,
                  linkedRecordType: option.__typename,
                },
              ],
            },
          },
        }).then((response) =>
          setFormResponse(response.updateFormResponse.formResponse),
        );
      } catch (error) {
        console.log(error);
      }
    }
    setFinalChoice(option);
    return delay(600).then(() => {
      if (document.getElementById(`form-response`)) {
        const scrollTop = document.getElementById(`form-response`).offsetTop;
        scroller.current.scrollTop = scrollTop - 24;
      }
    });
  };

  const slideTo = (index: number) => {
    if (document.getElementById(`input-${index}`)) {
      return delay(600)
        .then(() => {
          setFormState((prevState) => ({
            ...prevState,
            [formQuestions[index].id]: {
              questionStatus: 'isVisible',
              optionsStatus: 'isVisible',
            },
          }));
        })
        .then(() => {
          if (document.getElementById(`input-${index}`)) {
            const scrollTop = document.getElementById(
              `input-${index}`,
            ).offsetTop;
            scroller.current.scrollTop = scrollTop - 100;
          }
        });
    }
  };

  // this should update the current form response
  const selectAnswer = (input, option, index) => {
    const nextQuestion = formQuestions[index + 1];
    if (formResponse) {
      try {
        updateFormResponse(process.env.NEXT_PUBLIC_API_ENDPOINT, {
          input: {
            id: formResponse.id,
            attributes: {
              fieldValuesAttributes: [
                {
                  fieldId: input.field.id,
                  content: { value: option.id },
                  linkedRecordId: option.id,
                  linkedRecordType: option.__typename,
                },
              ],
            },
          },
        }).then((response) =>
          setFormResponse(response.updateFormResponse.formResponse),
        );
      } catch (error) {
        console.log(error);
      }
    }
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      ...{ [input.field.id]: option.id },
    }));
    setFormState((prevState) => ({
      ...prevState,
      ...(nextQuestion
        ? {
            [nextQuestion.id]: {
              questionStatus: 'isTyping',
            },
          }
        : {}),
    }));
    refetch({
      fieldValues: {
        ...answers,
        ...{ [input.field.id]: option.id },
      },
    }).then((response) => {
      slideTo(index + 1);
    });
  };

  const exitChat = () => {
    setInitiated(false);
    setFinalChoice(null);
    setAnswers({});
    setFormState({});
    seeLess();
    setFormResponse(null);
    introControls.start({
      x: '0%',
      opacity: 1,
      transition: { duration: 0.6 },
    });
    conversationControls.start({
      y: 0,
      transition: { duration: 0.6 },
    });
    scroller.current.scrollTop = 0;
  };

  // This should allow to create a new form response
  const reset = () => {
    setFinalChoice(null);
    setAnswers({});
    setFormState({});
    refetch({
      fieldValues: {},
    }).then(() => {
      conversationControls
        .start({
          x: 0,
          opacity: 1,
          transition: { duration: 0.6 },
        })
        .then(initiate);
    });
  };

  const seeMore = () => {
    infoControls.start({
      x: '0%',
      opacity: 1,
      transition: { duration: 0.6 },
    });
    conversationControls.start({
      x: '-50%',
      filter: 'blur(10px)',
      pointerEvents: 'none',
      opacity: 1,
      transition: { duration: 0.6 },
    });
  };

  const seeLess = () => {
    infoControls.start({
      x: '100%',
      opacity: 0,
      transition: { duration: 0.6 },
    });
    conversationControls.start({
      x: '0%',
      opacity: 1,
      filter: 'none',
      pointerEvents: 'all',
      transition: { duration: 0.6 },
    });
  };

  // this should update the current form response
  const resetAnswer = (input, index) => {
    setFinalChoice(null);
    if (formResponse) {
      try {
        console.log(formResponse.fieldValues);
        const fieldValue = formResponse.fieldValues.find(
          (fv) => fv.field.id === input.field.id,
        );
        updateFormResponse(process.env.NEXT_PUBLIC_API_ENDPOINT, {
          input: {
            id: formResponse.id,
            attributes: {
              fieldValuesAttributes: [
                {
                  id: fieldValue.id,
                  _destroy: true,
                },
              ],
            },
          },
        }).then((response) =>
          setFormResponse(response.updateFormResponse.formResponse),
        );
      } catch (error) {
        console.log(error);
      }
    }
    const question = formQuestions[index];
    const nextQuestions = formQuestions.slice(index + 1);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      ...nextQuestions.reduce((acc, curr) => {
        acc[curr.field.id] = null;
        return acc;
      }, {}),
      ...{ [input.field.id]: null },
    }));
    const scrollTop = document.getElementById(`input-${index}`).offsetTop;
    scroller.current.scrollTop = scrollTop - 120;
    setFormState((prevState) => ({
      ...prevState,
      ...nextQuestions.reduce((acc, curr, index) => {
        acc[curr.id] = {
          questionStatus: null,
        };
        return acc;
      }, {}),
      ...(question
        ? {
            [question.id]: {
              questionStatus: 'isTyping',
            },
          }
        : {}),
    }));
    scroller.current.scrollTop = scrollTop - 100;
    refetch({
      fieldValues: {
        ...answers,
        ...{ [input.field.id]: null },
      },
    }).then(() => {
      slideTo(index);
    });
  };

  return (
    <>
      <SectionWrapper tw="pb-10 relative">
        <div tw="absolute top-0 -mt-20" id="bot" />
        <MaxWidthContent>
          {!!header && <Heading>{parse(header)}</Heading>}
          {!!description && (
            <Subheading>
              <RichText>{parse(description)}</RichText>
            </Subheading>
          )}
          <div tw="my-4 flex items-stretch flex-col space-y-2 lg:(flex-row space-y-0 space-x-6)">
            {/* <motion.div
              key="bot-intro"
              animate={introControls}
              style={{
                zIndex: initiated ? 30 : 0,
              }}
              tw="relative flex items-stretch bg-white h-auto p-0 w-full lg:(h-full min-width[350px] flex-basis[30%])"
            >
              <BotIntro image={image} paragraph={paragraph} />
            </motion.div> */}
            <Wrapper
              css={[
                tw`border bg-white shadow-xl border-secondary rounded-lg height[65vh] flex flex-col max-w-4xl justify-center overflow-hidden relative transition-all min-height[600px] mx-auto`,
                initiated && tw`rounded-lg shadow-2xl`,
              ]}
              style={{
                // linear-gradient(180deg, rgba(11,120,162,0.05) 0%, rgba(11,120,162,0.02) 100%),
                // background: `linear-gradient(rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%), url("./patterns/memphis-mini.png"), #fff`,
                borderColor: initiated
                  ? 'rgba(11,120,162,0.9)'
                  : 'rgba(0,0,0,0.1)',
                zIndex: initiated ? 30 : 0,
                height: initiated ? '65vh' : '40vh',
              }}
              ref={chatbotRef}
            >
              {initiated && (
                <div tw="p-4 border-b border-dashed">
                  <UiduButton
                    onClick={() => setInitiated(false)}
                    iconBefore={<XIcon tw="h-5 w-5" />}
                  >
                    Chiudi
                  </UiduButton>
                </div>
              )}
              <motion.div
                animate={conversationControls}
                initial={{ x: '0' }}
                tw="relative overflow-y-auto flex-grow h-auto lg:h-full"
              >
                <div
                  tw="overflow-y-auto flex-grow scroll-behavior[smooth] h-auto lg:h-full grid"
                  ref={scroller}
                >
                  <div
                    tw="px-4 md:px-6 lg:px-10 h-full space-y-6 max-w-5xl mx-auto w-full py-4 md:py-8 pb-36!"
                    style={{
                      background: `linear-gradient(rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%), url("./patterns/memphis-mini.png"), #fff`,
                    }}
                  >
                    <>
                      {!initiated && (
                        <div tw="relative w-full h-full">
                          <BotMessageGroup
                            title="Beatrice"
                            isVisible
                            author={
                              <img
                                tw="w-8 h-8 rounded-full md:(w-14 h-14) border-2 shadow-sm"
                                src={image}
                                alt="Beatrice"
                              />
                            }
                          >
                            <BotMessage>
                              Come scoprire l&apos;offerta dei servizi del
                              territorio?
                            </BotMessage>
                          </BotMessageGroup>
                          <BotMessageGroup
                            title={'Tu - Seleziona una risposta'}
                            isReverse
                            isVisible
                          >
                            <BotMessageOption
                              onClick={(e) => {
                                conversationControls
                                  .start({
                                    x: 0,
                                    opacity: 1,
                                    transition: { duration: 0.6 },
                                  })
                                  .then(initiate);
                              }}
                            >
                              Raccontameli in base ai miei bisogni
                            </BotMessageOption>
                            <BotMessageOption
                              onClick={(e) => {
                                router.replace('#servizi');
                              }}
                            >
                              Mostrami le categorie
                            </BotMessageOption>
                            <BotMessageOption
                              onClick={(e) => {
                                router.replace('/scopri');
                              }}
                            >
                              Fammi cercare il servizio su mappa
                            </BotMessageOption>
                          </BotMessageGroup>
                        </div>
                      )}
                      {!loading &&
                        initiated &&
                        (formQuestions || []).map((input, index) => {
                          const { questionStatus, optionsStatus } =
                            formState[input.id] ?? {};
                          return (
                            <div
                              id={`input-${index}`}
                              key={`input-${input.id}`}
                            >
                              <BotMessageGroup
                                title="Beatrice"
                                isVisible={
                                  questionStatus === 'isVisible' ||
                                  questionStatus === 'isTyping'
                                }
                                author={
                                  <img
                                    tw="w-8 h-8 rounded-full md:(w-14 h-14) border-2 shadow-sm"
                                    src={image}
                                    alt="Beatrice"
                                  />
                                }
                              >
                                {index === 0 && (
                                  <BotMessage>Bene, cominciamo</BotMessage>
                                )}
                                <BotMessage
                                  isTyping={questionStatus === 'isTyping'}
                                >
                                  {input.label}
                                </BotMessage>
                              </BotMessageGroup>
                              <BotMessageGroup
                                title={
                                  answers[input.field.id]
                                    ? 'Tu'
                                    : 'Tu - Seleziona la risposta'
                                }
                                isReverse
                                isVisible={optionsStatus === 'isVisible'}
                              >
                                {answers[input.field.id] ? (
                                  <BotMessageOption
                                    isSelected
                                    onReset={() => resetAnswer(input, index)}
                                  >
                                    {
                                      input.field.modelItems.find(
                                        (m) => m.id === answers[input.field.id],
                                      )?.name
                                    }
                                  </BotMessageOption>
                                ) : (
                                  <>
                                    {index === formQuestions.length - 1 ? (
                                      <>
                                        {input.field.modelItems.length > 1 ? (
                                          <>
                                            {input.field.modelItems.map(
                                              (option) => (
                                                <BotMessageOption
                                                  key={option.id}
                                                  onClick={(e) => {
                                                    completeWith(input, option);
                                                  }}
                                                >
                                                  {option.name}
                                                </BotMessageOption>
                                              ),
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {input.field.modelItems.map(
                                              (option) => (
                                                <BotMessageOption
                                                  key={option.id}
                                                  onClick={(e) => {
                                                    completeWith(input, option);
                                                  }}
                                                >
                                                  {option.name}
                                                </BotMessageOption>
                                              ),
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {input.field.modelItems.map(
                                          (option) => (
                                            <BotMessageOption
                                              key={option.id}
                                              onClick={(e) => {
                                                selectAnswer(
                                                  input,
                                                  option,
                                                  index,
                                                );
                                              }}
                                            >
                                              {option.name}
                                            </BotMessageOption>
                                          ),
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </BotMessageGroup>
                            </div>
                          );
                        })}
                    </>
                    {finalChoice && (
                      <div id={`form-response`} key={`form-response`}>
                        <BotResponse
                          image={image}
                          finalChoice={finalChoice}
                          onMore={seeMore}
                          onReset={reset}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
              <motion.div
                animate={infoControls}
                initial={{ x: '100%', opacity: 0 }}
                tw="absolute inset-y-0 right-0 w-3/4 lg:w-1/2 bg-white shadow-xl"
              >
                <button
                  onClick={seeLess}
                  tw="border rounded-full bg-white top[1rem] left[-3rem] p-2 absolute"
                >
                  <XIcon tw="h-4 w-4" />
                </button>
                {finalChoice && (
                  <BotSeeMore finalChoice={finalChoice} onClose={seeLess} />
                )}
              </motion.div>
            </Wrapper>
          </div>
        </MaxWidthContent>
      </SectionWrapper>
      <Backdrop
        onClick={() => exitChat()}
        initial={{ opacity: 0, pointerEvents: 'none' }}
        animate={
          initiated
            ? {
                opacity: 1,

                pointerEvents: 'auto',
              }
            : { opacity: 0, pointerEvents: 'none' }
        }
      />
    </>
  );
};
