import UiduMap, { Marker as UiduMarker } from '@uidu/map';
import Popup from '@uidu/popup';
import React from 'react';

export function ClickableMarker({
  content,
  isOpen,
  onClick = () => {},
  onClose = () => {},
  ...props
}) {
  return (
    <Popup
      placement="top"
      offset={[0, 40]}
      trigger={(triggerProps) => (
        <button
          {...triggerProps}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          <UiduMarker {...props} tw="h-8 w-8 text-gray-800" />
        </button>
      )}
      content={() => <div tw="w-96">{content}</div>}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

export function Marker(props) {
  return <UiduMarker tw="h-8 w-8 text-gray-800" {...props} />;
}

export default function Map(props) {
  return (
    <UiduMap
      options={{
        styles: [
          {
            featureType: 'all',
            elementType: 'labels.text',
            stylers: [
              {
                color: '#878787',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                color: '#f9f5ed',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.attraction',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.attraction',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.attraction',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.business',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi.school',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#c9c9c9',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'all',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'transit.station.bus',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit.station.rail',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                color: '#aee0f4',
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
}
