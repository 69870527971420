export const data = {
  name: 'Footer',
  slug: 'footer',
  blocks: [
    {
      shortname: 'brand-logo',
      name: 'Brand Logo',
      position: 2,
      fields: [
        {
          shortname: 'logo',
          name: 'Logo',
          type: 'image',
          content: {
            alt: 'Beatrice Logo',
            src: '/unnamed.png',
          },
        },
      ],
    },
    {
      shortname: 'regione-logo',
      name: 'Regione Logo',
      position: 2,
      fields: [
        {
          shortname: 'logo',
          name: 'Logo',
          type: 'image',
          content: {
            alt: 'Regione Logo',
            src: '/conciliazione.png',
          },
        },
      ],
    },
    {
      shortname: 'valseriana-logo',
      name: 'Valseriana Logo',
      position: 2,
      fields: [
        {
          shortname: 'logo',
          name: 'Logo',
          type: 'image',
          content: {
            alt: 'Valseriana Logo',
            src: '/logo-ambito-valseriana.jpeg',
          },
        },
      ],
    },
    {
      shortname: 'socials',
      name: 'Socials',
      position: 1,
      fields: [
        {
          shortname: 'linkedin',
          name: 'Linkedin',
          type: 'link',
          content: {
            label: 'Linkedin',
            url: 'https://www.linkedin.com/company/beatrice-welfare-aziendale/',
            level: 1,
          },
        },
        
      ],
    },
  ],
};