import tw, { css, styled } from 'twin.macro';
import { Link } from '..';
import { findByShortname } from '../../../utils/common';

const ContentWrapper = tw.div`flex-1 bg-white p-3 flex flex-col justify-start`;
const Title = tw.h3`text-sm line-height[130%] font-semibold text-gray-800`;
const ImageWrapper = styled.img`
  ${tw`flex-shrink-0 h-16 w-16 lg:h-20 lg:w-20 bg-cover bg-center object-cover`}
`;
const CardWrapper = styled(Link)<{ $featuredImage: string }>`
  ${tw`w-full flex flex-col items-center text-center overflow-hidden`}

  ${({ $featuredImage }) =>
    !!$featuredImage &&
    css`
      ${ImageWrapper} {
        background-image: url(${$featuredImage});
      }
    `}
`;

interface Props {
  fields: any[];
}

export const Person: React.FC<Props> = ({ fields = [], ...rest }) => {
  if (!fields) return null;
  const name = findByShortname(fields, 'title')?.content?.value ?? '';
  const slug = findByShortname(fields, 'slug')?.content?.value ?? '';
  const cover = findByShortname(fields, 'image')?.content?.value?.url ?? null;

  return (
    <CardWrapper href={`/persona/${slug}`} $featuredImage={cover} {...rest}>
      <ImageWrapper src={cover} />
      <ContentWrapper>
        <Title>{name}</Title>
      </ContentWrapper>
    </CardWrapper>
  );
};
