import { useForm } from '@uidu/api.js/react';
import Button from '@uidu/button';
import Checkbox from '@uidu/checkbox';
import Form from '@uidu/form';
import parse from 'html-react-parser';
import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { Link, MaxWidthContent, StyledField, StyledTextarea } from '../Base';

const Wrapper = tw.div`w-full my-5 grid grid-cols-1 lg:(my-10 grid-cols-6 gap-10)`;
const ColumnLeft = tw.div`col-span-full lg:col-span-3`;
const ColumnRight = tw.div`col-span-full lg:(col-start-4 col-span-3)`;
const Heading = tw.div`font-bold text-lg text-white mb-5 lg:(text-4xl mb-10)`;
const FormWrapper = styled.div<{ bgImage: string }>`
  ${tw`w-full bg-secondary text-white h-auto py-8 lg:(py-20) relative overflow-hidden bg-cover bg-center`}
  ${({ bgImage }) =>
    !!bgImage &&
    css`
      background-image: linear-gradient(
          rgba(29, 38, 113, 0.7),
          rgba(0, 0, 220, 0.5)
        ),
        url(${bgImage});
    `}
`;

const StyledButton = styled(Button)<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) =>
    $isDisabled &&
    tw`opacity-80! pointer-events-none! mt-5 px-5 text-secondary`}
`;

function FieldRenderer({ input, index }) {
  const { field } = input;
  const formInputsProps = {
    name: input.id,
    layout: 'elementOnly',
    required: input.required,
  };
  let fieldKind = input.contactAttribute
    ? input.contactAttribute.kind
    : field.kind;

  switch (fieldKind) {
    case 'string':
      return (
        <StyledField
          type={fieldKind}
          placeholder={`${input.label}${input.required ? '*' : ''}`}
          {...formInputsProps}
        />
      );
    case 'email':
    case 'tel':
      return (
        <StyledField
          type={fieldKind}
          placeholder={`${input.label}${input.required ? '*' : ''}`}
          {...formInputsProps}
        />
      );
    case 'text':
      return (
        <StyledTextarea
          placeholder={`${input.label}${input.required ? '*' : ''}`}
          {...formInputsProps}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          label={
            <p>
              Ho letto e accettato l’
              <Link href="privacy" tw="underline! hover:text-primary!">
                informativa sulla privacy
              </Link>
              .*
            </p>
          }
          layout="elementOnly"
          tw="mt-5"
          {...formInputsProps}
        />
      );
    default:
      return null;
  }
}

export const ContactFormInfoBlock = ({ fields }) => {
  const title = findByShortname(fields, 'title')?.content.value ?? null;
  const bgImage = findByShortname(fields, 'cover')?.content ?? '';
  const titleSmall =
    findByShortname(fields, 'titoletto')?.content.value ?? null;
  const form = findByShortname(fields, 'contact-form')?.linkedRecord ?? null;

  const { formResponse, handleSubmit } = useForm({
    form,
  });

  return (
    <FormWrapper bgImage={bgImage?.value?.url ?? ''} tw="py-10">
      <MaxWidthContent>
        <Wrapper>
          <ColumnLeft>
            {!!title && <Heading>{parse(title)}</Heading>}
            <div tw="border-b border-2 w-60 mb-5 bg-white"></div>
            {!!titleSmall && <Heading>{parse(titleSmall)}</Heading>}
          </ColumnLeft>
          <ColumnRight>
            {form && (
              <>
                {formResponse ? (
                  <>
                    <p>Grazie, abbiamo ricevuto il suo messaggio.</p>
                    <p>Le risponderemo il prima possibile</p>
                  </>
                ) : (
                  <Form
                    handleSubmit={async (model) => handleSubmit(model, {})}
                    validateBeforeSubmit
                    footerRenderer={(props) => (
                      <StyledButton
                        type="submit"
                        $isDisabled={!props.canSubmit || props.loading}
                        {...props}
                      >
                        Invia
                      </StyledButton>
                    )}
                  >
                    <div tw="grid grid-cols-1 row-gap[8px]">
                      {form.inputs.edges.map(({ node: input }, index) => {
                        return (
                          <FieldRenderer
                            key={input.id}
                            input={input}
                            index={index}
                          />
                        );
                      })}
                    </div>
                  </Form>
                )}
              </>
            )}
          </ColumnRight>
        </Wrapper>
      </MaxWidthContent>
    </FormWrapper>
  );
};
