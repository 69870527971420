import { useFields } from '@uidu/api.js/react';
import parse from 'html-react-parser';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';
import { CardRenderer } from '../Base/Cards/CardRenderer';

const Heading = tw.h2`font-bold text-lg lg:text-4xl`;
const ColumnLeft = tw.div`space-y-3 lg:space-y-5`;
const Wrapper = tw.div`w-full py-10 grid grid-cols-1 gap-10 lg:(grid-cols-2 py-16) items-start`;
const TextWrapper = tw.p`text-gray-800 text-lg`;
const CardGrid = styled.div<{ columns: number }>`
  ${tw`grid grid-cols-1 gap-5 lg:grid-cols-2`}
`;

export const CommunityBlock = ({ fields }) => {
  const title = findByShortname(fields, 'titolo')?.content?.value ?? '';
  const text = findByShortname(fields, 'description')?.content?.value ?? '';
  const button = findByShortname(fields, 'button')?.content.value ?? null;
  const link = findByShortname(fields, 'slug')?.content.value ?? '';
  const cards = findByShortname(fields, 'preview-cards')?.content?.items ?? [];
  const cardStyle =
    findByShortname(fields, 'cards-style')?.content?.value.toLowerCase() ??
    'organizzazione';
  const { anchor } = useFields(fields);

  return (
    <MaxWidthContent tw="border-b relative">
      <div tw="absolute -top-16" id={anchor.replace('#', '')} />
      <Wrapper>
        <ColumnLeft>
          {!!title && <Heading tw="col-start-4 col-span-2">{title}</Heading>}
          <TextWrapper>
            <RichText>{parse(text)}</RichText>
          </TextWrapper>
          {/* {!!link && (
            <Button>
              <Link href={link?.url || link?.value || ''}>
                {link?.label || 'Vedi tutte'}
              </Link>
            </Button>
          )} */}
        </ColumnLeft>
        <CardGrid columns={cardStyle === 'organizzazione' ? 3 : 5}>
          {cards.slice(0, 6).map((card, index) => (
            <CardRenderer card={card} style={cardStyle} key={`card-${index}`} />
          ))}
        </CardGrid>
      </Wrapper>
    </MaxWidthContent>
  );
};
