import parse from 'html-react-parser';
import { PaintingLabel } from 'styles/styled';
import tw, { css, styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent } from '../Base';

const HeroWrapper = styled.div<{ bgImage: string }>`
  ${tw`w-full bg-gray-400 min-height[200px] text-white h-80 py-8 flex items-center lg:(height[512px] py-16) relative overflow-hidden bg-cover bg-center`}
  ${({ bgImage }) =>
    !!bgImage &&
    css`
      background-image: linear-gradient(
          rgba(var(--brand-secondary), 0.1),
          rgba(var(--brand-secondary), 0.1)
        ),
        url(${bgImage});
    `}
`;
const H1 = tw.h1`text-left w-full font-bold mb-3 text-4xl lg:(mb-6 text-5xl text-center) text-secondary`;

const Label = styled.p`
  ${tw`max-w-4xl mx-auto text-xl text-left text-gray-500 lg:(text-xl text-center)`}
`;

export const HeroBlock = ({ name, shortname, fields }) => {
  const header = findByShortname(fields, 'titolo')?.content?.value ?? null;
  const bgImage = findByShortname(fields, 'cover')?.content ?? '';
  const label = findByShortname(fields, 'label')?.content.value ?? null;
  const reference = findByShortname(fields, 'etichetta')?.content.value ?? null;

  return (
    <>
      <HeroWrapper bgImage={bgImage?.value?.url ?? ''}>
        <MaxWidthContent tw="flex flex-col items-center"></MaxWidthContent>
        {!!reference && <PaintingLabel>{parse(reference)}</PaintingLabel>}
      </HeroWrapper>
      <MaxWidthContent tw="mt-5 py-6">
        {!!header && <H1>{parse(header)}</H1>}
        {!!label && <Label>{parse(label)}</Label>}
      </MaxWidthContent>
    </>
  );
};
