import tw from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { Button, Link, MaxWidthContent } from '../Base';

const H1 = tw.h1`leading-none font-bold w-full text-lg mb-3 lg:(text-3xl w-3/4 py-10)`;

export const FaqBlock = ({ fields }) => {
  const title = findByShortname(fields, 'titolo')?.content?.value ?? '';
  const link = findByShortname(fields, 'link')?.content ?? '';

  return (
    <div tw="bg-gray-50 py-8">
      <MaxWidthContent>
        <div tw="flex flex-col md:(flex-row items-center) py-10">
          <H1>{title}</H1>
          {!!link && (
            <Button tw="lg:w-1/4">
              <Link href={link?.url || link?.value || ''}>
                {link?.label || 'Scopri di più'}
              </Link>
            </Button>
          )}
        </div>
      </MaxWidthContent>
    </div>
  );
};
