import { gql, useQuery } from '@apollo/client';
import parse from 'html-react-parser';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import tw, { styled } from 'twin.macro';
import QuoteSVG from '../../assets/icons/quote.svg';
import { Heading, SectionWrapper, Subheading } from '../../styles/styled';
import { findByShortname } from '../../utils/common';
import { Button, Link, MaxWidthContent, RichText, Slider } from '../Base';

const SlideWrapper = tw.div`m-4 shadow-lg rounded-lg relative`;
const StyledImage = styled(Image)`
  ${tw`rounded-t sr-only md:rounded-l`}
  object-fit: cover;
  width: 100% !important;
  position: relative !important;
  /* height: unset !important; */
  height: 100% !important;
`;
const QuoteWrapper = tw.div`box-content p-6 md:p-12 flex flex-col md:justify-center relative`;
const Quote = tw.div`text-sm mb-3`;
// const Text = tw.div`line-height[150%] text-center pb-5 lg:(px-60) px-5 text-base`;
const Tex = tw.div`text-lg md:text-xl font-thin`;

// Define each slide separately
const Slide = ({ slide }) => {
  const image = slide.cover;
  const quote = slide.abstract;
  const name = slide.name;
  const link = `/news/${slide.id}`;

  return (
    <SlideWrapper
      style={{
        background: `linear-gradient(180deg, rgba(var(--brand-secondary), .05), rgba(var(--brand-secondary), 0.01))`,
      }}
    >
      <div tw="grid grid-cols-1 grid-rows-2 height[800px] md:(grid-cols-2 grid-rows-1 height[600px])">
        {!!image && (
          <div tw="h-full w-full relative">
            <StyledImage
              src={image ?? ''}
              alt=""
              layout="fill"
              objectFit="cover"
            />
          </div>
        )}
        <QuoteWrapper>
          <div>
            <Quote>
              <QuoteSVG tw="h-8 w-8 md:(w-14 h-14) mb-3" />
              <RichText tw="font-bold lg:(text-3xl) text-xl line-clamp-5">
                {name && parse(name)}
              </RichText>
            </Quote>
            {quote && (
              <RichText tw="text-base!">
                <Tex tw="mb-6! line-clamp-4">{parse(quote)}</Tex>
              </RichText>
            )}
            <Button tabIndex={-1}>Leggi di più</Button>
          </div>
        </QuoteWrapper>
      </div>
      {!!link && <Link href={link} tw="inset-0 absolute" />}
    </SlideWrapper>
  );
};

export const TestimonialsBlock = ({ fields }) => {
  const { data } = useQuery(
    gql`
      query storiesQuery {
        currentWorkspace {
          search(
            models: ["Story"]
            params: {
              term: "*"
              filters: [
                { id: "tags", value: ["interviste"] }
                { id: "published", value: true }
              ]
            }
          ) {
            edges {
              node {
                ... on Story {
                  id
                  name
                  cover
                  abstract
                  storyTagList: tags(scope: "story_tags")
                  createdAt
                }
              }
            }
          }
        }
      }
    `,
    { variables: {} },
  );
  // create an array of slides as components
  const description =
    findByShortname(fields, 'description')?.content.value ?? null;
  const [isMobile, setIsMobile] = useState(false);
  const title = findByShortname(fields, 'titolo')?.content?.value ?? '';
  const { width } = useWindowSize();

  const slides = (
    data?.currentWorkspace.search.edges?.map((edge) => edge.node) || []
  ).map((item, i) => <Slide key={`quote-${i}`} slide={item} />);

  useEffect(() => {
    if (width <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <SectionWrapper tw="pt-20 pb-12 border-t border-b border-dashed">
      <MaxWidthContent tw="px-0 md:px-20">
        {!!title && <Heading>{title}</Heading>}
        {!!description && (
          <Subheading>
            <RichText>{parse(description)}</RichText>
          </Subheading>
        )}
        <Slider
          // feed slides as array of components
          slides={slides}
          hasNavigation={!isMobile}
          hasPagination
          loop
          pagination={{
            dynamicBullets: true,
            el: '.swiper-pagination',
          }}
          slidesPerView={1}
          breakpoints={{
            0: { spaceBetween: 10 },
            480: { slidesPerView: 1.2, spaceBetween: 20 },
            768: { slidesPerView: 1, spaceBetween: 40 },
          }}
        />
        <div tw="relative text-center h-10 mt-6">
          <div className="swiper-pagination" />
        </div>
      </MaxWidthContent>
    </SectionWrapper>
  );
};
