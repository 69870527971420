import { useForm } from '@uidu/api.js/react';
import Form, { FormSubmit } from '@uidu/form';
import FormQuestionRenderer from 'components/Base/FormQuestionRenderer';
import React from 'react';
import tw from 'twin.macro';

const FormWrapper = tw.div`text-xl`;

export default function EbookForm({ form }) {
  const { formResponse, handleSubmit } = useForm({
    form,
  });

  return (
    <FormWrapper>
      {form && (
        <>
          {formResponse ? (
            <div tw="w-full text-2xl h-screen pt-20">
              <>
                Scarica{' '}
                <a
                  href="https://drive.google.com/file/d/1Qaza_Ppq9DFfmjCF1tknBXZ68V_oPQPV/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  tw="text-secondary underline"
                >
                  qui{' '}
                </a>{' '}
                il nostro Ebook
              </>
            </div>
          ) : (
            <Form
              tw="mx-5 py-5 text-gray-800!"
              handleSubmit={async (model) => handleSubmit(model, {})}
              footerRenderer={(props) => (
                <FormSubmit
                  tw="flex justify-center px-12 py-2.5 mt-5 rounded-lg text-lg font-bold opacity-70 text-white bg-secondary uppercase"
                  shouldFitContainer
                  {...props}
                >
                  Scarica
                </FormSubmit>
              )}
            >
              <div tw="space-y-4">
                {form?.inputs.edges
                  .map((edge) => edge.node)
                  .map((question, index) => {
                    return (
                      <div key={question.id}>
                        <FormQuestionRenderer
                          layout="elementOnly"
                          formQuestion={question}
                          index={index}
                        />
                      </div>
                    );
                  })}
              </div>
            </Form>
          )}
        </>
      )}
    </FormWrapper>
  );
}
