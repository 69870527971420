import Field from '@uidu/field';
import EbookForm from 'components/Custom/EbookForm';
import parse from 'html-react-parser';
import Image from 'next/image';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const Title = tw.h2`font-bold text-white py-2 text-lg lg:(text-3xl) mb-4 sm:mb-4`;
const StyledField = tw(Field)`bg-white! rounded-lg! border-none!`;
const FormWrapper = tw.div`text-xl mt-10`;

const ImageWrapper = styled.div`
  ${tw`relative min-height[400px] lg:(col-start-2 col-span-2)`}
`;
const LogoWrapper = styled.div`
  ${tw``}
`;
const StyledImage = styled(Image)`
  ${tw`h-auto`}
  img {
    ${tw`h-auto!`}
  }
`;
const TextWrapper = styled.div`
  ${tw`py-10 col-span-full lg:(col-start-1 col-span-1) text-center`}
`;
const ContentGrid = styled.div`
  ${tw`relative w-full grid grid-cols-1 column-gap[100px] pt-20 pb-10 lg:(py-20 grid-cols-2 column-gap[40px])`}
`;

export const EbookBlock = ({ fields }) => {
  const title = findByShortname(fields, 'titolo')?.content?.value ?? null;
  const description =
    findByShortname(fields, 'curatori')?.content?.value ?? null;
  const image =
    findByShortname(fields, 'immagine')?.content?.value?.url ?? null;
  const logo = findByShortname(fields, 'logo')?.content?.value?.url ?? null;
  const form = findByShortname(fields, 'ebook-form').linkedRecord ?? null;

  return (
    <div tw="relative overflow-hidden lg:(pt-10)">
      <div
        tw="bg-primary absolute w-full h-full z-behind transform -skew-y-6 -top-2/3"
        style={{
          background: `linear-gradient(270deg, rgba(var(--brand-primary), 1), rgba(var(--brand-primary), 0.7)`,
        }}
      ></div>
      <MaxWidthContent>
        <ContentGrid>
          <TextWrapper>
            {!!title && <Title>{title}</Title>}
            <div tw="col-span-full lg:col-span-4 text-gray-800 flex">
              <div tw="flex flex-row lg:space-x-px lg:flex-col items-start text-right">
                <div></div>
              </div>
              <div tw="px-5 w-full flex-row">
                <p tw="mb-5 text-base text-white">
                  Scarica il nostro Ebook sul Welfare Aziendale
                </p>
                <FormWrapper tw="w-full">
                  <EbookForm form={form} />
                </FormWrapper>
                {/* <Button as="a" tw="text-xl font-bold text-white uppercase bg-secondary p-5 rounded-lg mt-20">
                  <Link href={'https://mail-attachment.googleusercontent.com/attachment/u/0/?ui=2&ik=43253f9661&attid=0.2&permmsgid=msg-f:1724905965569365560&th=17f018d634b6d638&view=att&disp=inline&realattid=f_kzo9kdmh0&saddbat=ANGjdJ8MXYOeDou6Beze9BNKAihv8IemryzmnEfnR4oLCNPE3xHSAcNGC7HvCk2OFq8Ps-0CfmiMP9nSI9DdK6Ilue1yMOvbFT87W5GSIDfzSUW3B5qMyl2A4Wo3pCSBYSenzM-vHQ9FH12OH0lA-M0F8_0k3YvP-TpbHJgWuANOiW_LGw_6CNdrBwSH6pUIJFlQ518R1r9LjlNBHW_A6X4J5FakU2Hw5eLnJGoKs9ZM7WtHHueloLoGcp46zSP0UIjzVlw7oBA4kivuC90O5M298kqUyLmHRUwYNeJlZf0JPdKpTAsrrVfxboIAQedSEpn7Z0-Nv034YwILxJkGVMBC9FRvG8Xbep1rKdyRG_grjU9rtQ1OUCGKRfzpjyRPurhzyJ9_8MVdZ56K8XSQaOAVlhbNEdpN-YQbRHUilo02RbJKc0OFezZ8sHaa_pGAL3FRumMvZRFpOEtXYPf_dWfJ-QWRHmcjoQKqUWU5r8cJMuea-YNg1ISDZIL6-zFSll-OC-Gg0s2AN-87nmIjPgnW1u7QOjU1R4piDnTlO0YJO7zySZ0wEORtB8Lpn65ieEXv14_wlLpi1ncyfHXCzlVtO1YA42_fUlp13mhQ-ev5yRh3gTTcvhYV3MiB8I7cpiPPGo1DyShvUyDT2z9vUyJTpxNk3FSCXQG_x4zPf6LUCzDhZly9N9M7AilLjJU'}>
                    {'Scarica gratuitamente'}
                  </Link>
                </Button> */}
              </div>
            </div>
          </TextWrapper>
          <ImageWrapper>
            {!!image && (
              <StyledImage
                src={image}
                alt={''}
                layout="fill"
                objectFit="contain"
                // objectFit="scale-down"
                // objectFit="none"
                /* objectPosition={isInverted ? '0 0' : '100 0'} */
              />
            )}
          </ImageWrapper>
        </ContentGrid>
      </MaxWidthContent>
      <div tw="bg-primary py-10">
        <div tw="flex flex-col lg:(flex-row items-center) w-full px-4 mx-auto max-w-screen-max-content lg:px-8">
          {!!description && (
            <RichText tw="text-white pb-0! mb-0 text-lg text-center lg:(text-xl text-left) order-2 lg:order-1">
              {parse(description)}
            </RichText>
          )}
          <div tw="order-1 lg:order-2 relative min-height[100px] mb-10 lg:(w-1/3 mb-0)">
            {!!logo && (
              <StyledImage
                src={logo}
                alt={''}
                layout="fill"
                objectFit="contain"
                // objectFit="scale-down"
                // objectFit="none"
                /* objectPosition={isInverted ? '0 0' : '100 0'} */
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
