import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { Button, Link, MaxWidthContent } from '../Base';

const Heading = tw.h4`text-base p-2.5`;
const TabsWrapper = tw.div`w-full items-center h-14 lg:h-full text-right flex`;
const ButtonWrapper = tw.div`p-0`;
const TabItem = styled.div<{ $isActive?: boolean }>`
  ${tw`flex items-center p-2.5 space-x-3 font-thin md:space-x-6 xl:space-x-2 focus:font-bold`}

  ${({ $isActive }) => $isActive && tw`font-bold bg-gray-100 rounded-xl`}
`;
const Tab = ({ tab }) => {
  const title = findByShortname(tab.fields, 'tab')?.content.value ?? '';

  return <div tw="rounded-sm">{!!title && <Heading>{title}</Heading>}</div>;
};
export const TabsBlock = ({ fields }) => {
  const tabs = findByShortname(fields, 'tabs')?.content?.items ?? [];
  const button = findByShortname(fields, 'bottone')?.content.value ?? null;

  return (
    <div tw="border-b border-t border-gray-200 mb-2.5">
      <MaxWidthContent>
        <TabsWrapper>
          <Link href="/servizio">
            <TabItem>
              {tabs.map((tab, index) => (
                <Tab tab={tab} key={`tab-${index}`} />
              ))}
            </TabItem>
          </Link>
          <ButtonWrapper>
            <Button tw="uppercase rounded-lg bg-green-500">
              <p>{button}</p>
            </Button>
          </ButtonWrapper>
        </TabsWrapper>
      </MaxWidthContent>
    </div>
  );
};
