import parse from 'html-react-parser';
import Image from 'next/image';
import { useRouter } from 'next/router';
import tw, { css, styled } from 'twin.macro';
import { SectionWrapper } from '../../styles/styled';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const TextWrapper = styled.div`
  ${tw`py-3 col-span-full lg:(py-10 col-end-7 col-span-2) text-gray-800`}
`;
const ImageWrapper = styled.div`
  ${tw`relative min-height[300px] col-span-full lg:(col-span-4 min-height[400px])`}
`;
const StyledImage = styled(Image)`
  ${tw`w-full! h-auto`}
  img {
    ${tw`w-full! h-auto!`}
  }
`;
const Paragraph = styled.div`
  ${tw`line-height[140%] text-base lg:text-lg`}
`;
const Heading = styled.h3`
  ${tw`relative w-full pb-5 text-lg font-medium uppercase lg:text-3xl text-primary`}
  span {
    ${tw`box-shadow[0 0 0 10px transparent] bg-gray-100 line-height[150%]`}
  }
`;
const ContentGrid = styled.div<{ $isInverted?: boolean; isBlog?: boolean }>`
  ${tw`relative w-full grid grid-cols-1 gap-5 lg:(grid-cols-7 gap-10)`}
  ${({ $isInverted }) =>
    $isInverted &&
    css`
      ${ImageWrapper} {
        ${tw`order-last!`}
      }
    `}
  ${({ $isBlog }) =>
    $isBlog &&
    css`
      ${tw`flex flex-col max-w-3xl gap-5 mx-auto space-y-3`}
      ${ImageWrapper}, ${TextWrapper} {
        ${tw`w-full`}
      }
    `}
`;

export const ImageParagraphBlockAziende = ({ fields }) => {
  const router = useRouter();
  const heading = findByShortname(fields, 'titolo')?.content.value ?? null;
  const paragraph = findByShortname(fields, 'paragraph')?.content.value ?? null;
  const image = findByShortname(fields, 'image')?.content?.value?.url ?? null;
  const isInverted =
    findByShortname(fields, 'isInverted')?.content?.value ?? false;
  const isBlog = router?.pathname?.includes('aziende');

  return (
    <SectionWrapper>
      <MaxWidthContent>
        <ContentGrid $isInverted={isInverted} $isBlog={isBlog}>
          <TextWrapper>
            {!!heading && <Heading>{parse(heading)}</Heading>}
            {!!paragraph && (
              <Paragraph>
                <RichText>{parse(paragraph)}</RichText>
              </Paragraph>
            )}
          </TextWrapper>
          <ImageWrapper>
            {!!image && (
              <StyledImage
                src={image}
                alt={''}
                layout="fill"
                objectFit="contain"
                // objectFit="scale-down"
                // objectFit="none"
                /* objectPosition={isInverted ? '0 0' : '100 0'} */
              />
            )}
          </ImageWrapper>
        </ContentGrid>
      </MaxWidthContent>
    </SectionWrapper>
  );
};
