import { ArrowRightIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import React from 'react';
import BotMessage from '../Custom/BotMessage';
import BotMessageGroup from '../Custom/BotMessageGroup';
import BotMessageOption from '../Custom/BotMessageOption';

export default function BotResponse({
  image,
  finalChoice,
  onReset,
  onNext,
  onMore,
}) {
  const router = useRouter();

  return (
    <>
      <BotMessageGroup
        title="Beatrice"
        isVisible
        author={
          <img
            tw="w-8 h-8 rounded-full md:(w-14 h-14)"
            src={image}
            alt="Beatrice"
          />
        }
      >
        <BotMessage>La soluzione che hai selezionato è</BotMessage>
        <BotMessage>{finalChoice.name}</BotMessage>
      </BotMessageGroup>
      <BotMessageGroup isReverse title="Tu" isVisible>
        <BotMessageOption onClick={onMore} tw="flex items-center space-x-4">
          <span>Dimmi di più in merito</span>
        </BotMessageOption>
        <BotMessageOption
          onClick={() => {
            router.push(`/scopri?soluzioni_ids=${finalChoice.id}`);
          }}
          tw="flex items-center space-x-3"
        >
          <ArrowRightIcon tw="h-5 w-5" />
          <span>Mostrami dove posso rivolgermi</span>
        </BotMessageOption>
        <button tw="text-sm mt-2!" onClick={onReset}>
          Ricomincia
        </button>
      </BotMessageGroup>
    </>
  );
}
