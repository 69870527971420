import { gql, useQuery } from '@apollo/client';
import parse from 'html-react-parser';

export default function BotSeeMore({ finalChoice, onClose }) {
  const { data, loading, error } = useQuery(
    gql`
      query soluzioneQuery($modelItemId: ID!) {
        currentWorkspace {
          modelItem(id: $modelItemId) {
            id
            fieldValues: fieldValuesByShortname
          }
        }
      }
    `,
    { variables: { modelItemId: finalChoice.id } },
  );

  const fieldValues = data?.currentWorkspace?.modelItem?.fieldValues;

  if (loading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <div tw="overflow-auto p-6 pb-20 h-full">
        {/* {fieldValues.cover && (
          <div tw="aspect-w-16 aspect-h-9 mb-6 rounded">
            <img src={fieldValues.cover.url} tw="object-cover rounded" />
          </div>
        )} */}
        <h4 tw="text-2xl font-semibold">{fieldValues.name}</h4>
        <div tw="space-y-8 my-8">
          {fieldValues.description && fieldValues.description !== '<p></p>' && (
            <div tw="prose">
              <h4>Descrizione</h4>
              {parse(fieldValues.description)}
            </div>
          )}
          {fieldValues.beneficiaries &&
            fieldValues.beneficiaries !== '<p></p>' && (
              <div tw="prose">
                <h4>A chi si rivolge</h4>
                {parse(fieldValues.beneficiaries)}
              </div>
            )}
          {fieldValues.benefits && fieldValues.benefits !== '<p></p>' && (
            <div tw="prose">
              <h4>Quali benefici</h4>
              {parse(fieldValues.benefits)}
            </div>
          )}
          {fieldValues.providers && fieldValues.providers !== '<p></p>' && (
            <div tw="prose">
              <h4>A chi posso rivolgermi</h4>
              {parse(fieldValues.providers)}
            </div>
          )}
          {fieldValues.costs && fieldValues.costs !== '<p></p>' && (
            <div tw="prose">
              <h4>Quanto costa</h4>
              {parse(fieldValues.costs)}
            </div>
          )}
          {fieldValues.values && fieldValues.values !== '<p></p>' && (
            <div tw="prose">
              <h4>Valori distintivi</h4>
              {parse(fieldValues.values)}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
