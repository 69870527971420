import Map, { Marker } from 'components/Custom/Map';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';

const Wrapper = styled.div`
  ${tw`relative flex items-end justify-center w-full overflow-hidden text-white bg-gray-400 bg-center bg-cover h-80 lg:h-96`}
`;

export const MapBlock = (props) => {
  const address =
    findByShortname(props?.fields, 'indirizzo')?.content?.value ?? '';
  const lat = findByShortname(props?.fields, 'latitudine')?.content?.value;
  const lon = findByShortname(props?.fields, 'longitudine')?.content?.value;

  const defaultProps = {
    center: {
      lat: parseFloat(lat),
      lng: parseFloat(lon),
    },
    zoom: 13,
  };

  if (!lat || !lon) return null;

  return (
    <Wrapper>
      <Map
        bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY }}
        containerElement={<div style={{ height: '100%', width: '100%' }} />}
        mapElement={<div style={{ height: '100%', width: '100%' }} />}
        location={{
          lat: lat,
          lon: lon,
        }}
        hasMarker
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker lat={lat} lng={lon} text={address} />
      </Map>
    </Wrapper>
  );
};
