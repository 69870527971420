import { MaxWidthContent, RichText } from "../Base";
import { findByShortname } from "../../utils/common";
import tw from "twin.macro";
import parse from "html-react-parser";


const Heading = tw.h4`font-bold text-lg mb-2.5 text-primary p-5 uppercase`;
const Text = ({ text }) => {
  const title =
    findByShortname(text.fields, "titolo")?.content?.value ?? null;
    const paragraph =
    findByShortname(text.fields, "paragrafo")?.content?.value ?? null;
  return (
    <div>
      {!!title && <Heading>- {title}</Heading>}
      {!!paragraph && <RichText>{parse(paragraph)}</RichText>}
    </div>
  )
}

export const PrivacyText = ({ fields }) => {
  const texts =
    findByShortname(fields, "info-text")?.content?.items ?? [];
  return ( 
    <MaxWidthContent tw="my-12 py-12 border-b border-secondary">
    {texts.map((text, index) => (
      <Text text={text} key={`text-${index}`} />
    ))}
  </MaxWidthContent>
  )
}