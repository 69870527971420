import { MenuIcon, XIcon } from '@heroicons/react/outline';
import UserDropdown from 'components/Custom/UserDropdown';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { data as aziendeList } from '../../data/aziende.data';
import { data as mainNavigation } from '../../data/mainNavigation.data';
import { findByShortname } from '../../utils/common';
import { Button, Link, MaxWidthContent } from '../Base';

const SubMenuWrapper = styled.div`
  ${tw`absolute left-0 flex flex-col invisible space-y-5 transform translate-y-10 opacity-0 top-40`}
  transition: visibility 100ms ease, opacity 100ms, transform 100ms ease;
`;
const SubMenuItem = styled(Link)<{ $isActive?: boolean }>`
  ${tw`w-full p-6 font-bold text-white bg-gray-400 md:text-base hover:bg-primary whitespace-nowrap`}
  ${({ $isActive }) => $isActive && tw`bg-primary`}
`;

const MobileSubMenuWrapper = styled.div`
  ${tw`flex flex-col space-y-5`}
`;
const MobileSubmenuButton = styled.button`
  ${tw`flex items-center justify-center px-8 rounded`}
  svg {
    ${tw`transform rotate-0`}
  }
`;

const AccordionHeader = tw.button`w-full flex justify-between items-center text-left`;
const AccordionBody = styled.div`hidden`;

const MobileSubMenuItem = tw.div`w-full flex flex-col border-b border-gray-200`;
const Accordion = styled.div<{ $isSubmenuOpen?: boolean }>`
  ${tw`flex flex-col w-full`}
  ${({ $isSubmenuOpen }) =>
    $isSubmenuOpen &&
    css`
      ${AccordionBody} {
        ${tw`block`}
      }
      ${MobileSubmenuButton} svg {
        ${tw`transform rotate-90`}
      }
    `}
`;

const NavWrapper = tw.div`w-full h-20 fixed left-0 right-0 top-0 z-50 flex justify-center bg-white shadow-md`;
const NavContent = tw(
  MaxWidthContent,
)`w-full flex items-center justify-between`;
const NavItems = tw.nav`flex items-center space-x-3 md:space-x-4 xl:space-x-4`;
const NavLink = styled(Link)<{ $isActive?: boolean }>`
  ${tw`p-2 font-medium border-l md:(border-b) border-transparent border-opacity-20 whitespace-nowrap`}
  ${tw`border-l-2 border-l-transparent md:(border-l-0 border-b-2 border-b-transparent) hover:(border-l-secondary md:(border-b-secondary border-l-0) border-opacity-20 )`}
  ${({ $isActive }) =>
    $isActive &&
    tw`border-l-2 border-l-secondary md:(border-l-0 border-b-2 border-opacity-20 border-b-secondary) font-semibold`}
`;
const DesktopNavWrapper = tw.div`hidden lg:flex items-center`;
const MobileNavWrapper = tw.div`flex lg:hidden items-center px-4`;
const MobileMenuButton = tw.button`flex items-center justify-center rounded`;
const MobileMenuWrapper = tw.div`fixed z-50 bottom-0 top-20 py-4 w-screen height[calc(100vh - 5rem)] flex flex-col bg-white text-black`;

export const MainNavigation = ({ navigation }) => {
  const { data: session } = useSession();
  const router = useRouter();
  const brandLogo = findByShortname(mainNavigation.blocks, 'brand-logo');
  const button = findByShortname(mainNavigation.blocks, 'accedi');
  const { blocks: aziende } = aziendeList;
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [subMenuIsOpen, setSubMenuIsOpen] = useState<boolean>(false);

  return (
    <>
      <NavWrapper>
        <NavContent>
          <div tw="flex-shrink-0">
            <Link href="/">
              <img
                src={findByShortname(brandLogo.fields, 'logo').content.src}
                alt={findByShortname(brandLogo.fields, 'logo').content.altText}
                tw="w-auto h-10 md:(h-10)"
              />
            </Link>
          </div>
          <DesktopNavWrapper>
            <NavItems>
              {!!navigation.fields &&
                navigation.fields?.map((link) => (
                  <NavLink
                    href={link?.content?.url}
                    key={link?.shortname}
                    $isActive={router.asPath.startsWith(link.content.url)}
                  >
                    {link?.content?.label}
                  </NavLink>
                ))}
              {!!button && (
                <div tw="md:ml-6!">
                  {session ? (
                    <UserDropdown session={session} />
                  ) : (
                    <Button>
                      <Link href={button?.content?.url || button?.value || ''}>
                        {button?.label || 'ACCEDI'}
                      </Link>
                    </Button>
                  )}
                </div>
              )}
            </NavItems>
          </DesktopNavWrapper>
          <MobileNavWrapper>
            <MobileMenuButton onClick={() => setMenuIsOpen((prev) => !prev)}>
              {menuIsOpen ? (
                <XIcon tw="w-6 h-6 text-black" />
              ) : (
                <MenuIcon tw="w-6 h-6  text-black" />
              )}
            </MobileMenuButton>
          </MobileNavWrapper>
        </NavContent>
      </NavWrapper>
      {menuIsOpen && (
        <MobileMenuWrapper>
          <MaxWidthContent tw="flex flex-col text-xl space-y-2">
            {!!navigation.fields &&
              navigation.fields?.map((link) => (
                <NavLink
                  // onClick={() => setMenuIsOpen(false)}
                  href={link?.content?.url}
                  key={link?.shortname}
                  $isActive={router.asPath.includes(link.content.url)}
                >
                  {link?.content?.label}
                </NavLink>
              ))}
            {!!button && (
              <>
                {session ? (
                  <Button onClick={() => signOut()}>Signout</Button>
                ) : (
                  <Button tw="w-full mt-8!">
                    <Link href={button?.content?.url || button?.value || ''}>
                      {button?.label || 'ACCEDI'}
                    </Link>
                  </Button>
                )}
              </>
            )}
          </MaxWidthContent>
        </MobileMenuWrapper>
      )}
    </>
  );
};
