import parse from 'html-react-parser';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const Paragraph = styled.div`
  ${tw`leading-loose! text-gray-800 text-base col-span-full lg:(col-span-2 text-lg)`}
`;

const Heading = tw.h2`text-left uppercase text-primary font-medium text-lg lg:text-3xl mb-3`;

export const TextBlock = ({ fields }) => {
  const title = findByShortname(fields, 'titolo')?.content?.value ?? '';
  const text = findByShortname(fields, 'paragrafo')?.content?.value ?? '';

  return (
    <MaxWidthContent tw="max-w-5xl my-8">
      {!!title && <Heading>{title}</Heading>}
      {!!text && (
        <Paragraph>
          <RichText tw="prose-lg lg:prose-lg">{parse(text)}</RichText>
        </Paragraph>
      )}
    </MaxWidthContent>
  );
};
