import parse from 'html-react-parser';
import Image from 'next/image';
import CountUp from 'react-countup';
import tw, { styled } from 'twin.macro';
import { Heading, Subheading } from '../../styles/styled';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const Wrapper = tw.div`py-10 lg:py-14`;
const Grid = tw.div`w-full grid grid-cols-2 sm:row-gap[40px] lg:(grid-cols-4 gap-0 row-gap[80px])`;
const StatWrapper = styled.div`
  ${tw`text-center p-5 py-8 flex flex-col items-center space-y-3 sm:(py-5)`}
`;

const Value = tw.span`font-bold text-primary text-2xl lg:text-4xl`;
const Label = tw.p`text-base uppercase text-gray-800`;
const IconWrapper = tw.div`relative self-center`;
const StyledImage = tw(Image)`w-full! h-8 mb-8`;

export const StatsGridBlock = ({ fields }) => {
  const title = findByShortname(fields, 'titolo')?.content?.value ?? '';
  const stats = findByShortname(fields, 'stats')?.content?.items ?? [];
  const description =
    findByShortname(fields, 'description')?.content.value ?? null;

  return (
    <Wrapper>
      <MaxWidthContent>
        {!!title && <Heading>{title}</Heading>}
        {!!description && (
          <Subheading>
            <RichText>{parse(description)}</RichText>
          </Subheading>
        )}
        <Grid>
          {stats.map((stat) => {
            const value =
              findByShortname(stat.fields, 'value')?.content?.value ?? '';
            const label =
              findByShortname(stat.fields, 'label')?.content?.value ?? '';
            const image =
              findByShortname(stat.fields, 'icona')?.content?.value?.url ??
              null;

            return (
              <StatWrapper key={label}>
                <IconWrapper>
                  {!!image && (
                    <StyledImage
                      src={image}
                      alt={title ?? ''}
                      width={120}
                      height={70}
                    />
                  )}
                </IconWrapper>
                <Value>
                  <CountUp end={value} duration={5} delay={5} />
                </Value>
                <Label>{label}</Label>
              </StatWrapper>
            );
          })}
        </Grid>
      </MaxWidthContent>
    </Wrapper>
  );
};
