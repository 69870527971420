import parse from 'html-react-parser';
import tw, { styled } from 'twin.macro';
import { Heading, SectionWrapper } from '../../styles/styled';
import { findByShortname } from '../../utils/common';
import { MaxWidthContent, RichText } from '../Base';

const IconWrapper = tw.div`relative mb-5 self-center`;
const Title = tw.h4`text-secondary font-bold text-lg mb-2.5 text-center md:(text-xl)`;
const ContentGrid = styled.div<{ $isInverted?: boolean }>`
  ${tw`relative w-full grid grid-cols-2 pb-5 gap-8 md:(grid-cols-2 gap-8) lg:(pb-5 pt-10 grid-cols-3 gap-12)`}
`;

const Value = ({ value }) => {
  const image =
    findByShortname(value.fields, 'image')?.content?.value?.url ?? null;
  const title = findByShortname(value.fields, 'title')?.content.value ?? '';
  const paragraph =
    parse(findByShortname(value.fields, 'description')?.content.value) ?? '';

  return (
    <div tw="flex flex-col">
      <IconWrapper>
        {!!image && (
          <img
            tw="h-10 w-10 md:(h-14 w-14 mb-4)"
            src={image}
            alt={title ?? ''}
          />
        )}
      </IconWrapper>
      {!!title && <Title>{title}</Title>}
      {!!paragraph && (
        <RichText tw="text-center md:(text-lg)">{paragraph}</RichText>
      )}
    </div>
  );
};

export const ValuesGridBlock = ({ fields }) => {
  const values = findByShortname(fields, 'values')?.content?.items ?? [];
  const header = findByShortname(fields, 'titolo')?.content.value ?? '';

  return (
    <SectionWrapper>
      <MaxWidthContent>
        {!!header && <Heading as={'h4'}>{header}</Heading>}
        <ContentGrid tw="my-8">
          {values.map((value, index) => (
            <Value value={value} key={`value-${index}`} />
          ))}
        </ContentGrid>
      </MaxWidthContent>
    </SectionWrapper>
  );
};
