import tw, { css, styled } from 'twin.macro';

const HeroWrapper = styled.div<{ bgImage: string }>`
  ${tw`hidden lg:block w-full bg-gray-400 min-height[100px] text-white h-auto py-8 lg:(height[120px]) relative overflow-hidden bg-cover bg-center`}
  ${({ bgImage }) =>
    !!bgImage &&
    css`
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url('./hero-bg.png');
    `}
`;

export const MiniHeroBlock = ({}) => {
  return <HeroWrapper bgImage={'/hero-bg.png'}></HeroWrapper>;
};
