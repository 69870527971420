import { gql, useQuery } from '@apollo/client';
import { SearchIcon } from '@heroicons/react/outline';
import Form, { FormSubmit } from '@uidu/form';
import { ButtonItem, MenuGroup } from '@uidu/menu';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';
import tw from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { Button, MaxWidthContent, StyledField } from '../Base';

const InfoItem = tw.div`bg-white p-2.5 w-full rounded cursor-pointer flex items-center`;

export const SearchBlockHome = ({ fields }) => {
  const router = useRouter();
  const [term, setTerm] = useState('*');
  const searchService =
    findByShortname(fields, 'search-1')?.content?.value ?? '';
  const button = findByShortname(fields, 'button')?.content?.value ?? '';

  const { data, loading, error } = useQuery(
    gql`
      query searchQuery($models: [String!]!, $params: SearchParams) {
        currentWorkspace {
          id
          search(last: 10, models: $models, params: $params) {
            totalCount
            totalPages
            aggs
            edges {
              node {
                __typename
                ... on Story {
                  id
                  name
                }
                ... on ServiceInstance {
                  id
                  service {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        models: ['ServiceInstance'],
        params: {
          // ...params,
          filters: [{ id: 'published', value: true }], // params.filters || [
          // where: { published: true },
          // per_page: 20,
          term: term,
        },
      },
    },
  );

  const debouncedSearch = debounce(setTerm, 500);

  const isAutosuggesting = term && term !== '*' && data;

  return (
    <div tw="bg-subtle py-3 md:py-5">
      <MaxWidthContent>
        <Form
          tw="grid grid-template-columns[1fr min-content] gap-y-3 gap-x-3 md:grid-template-columns[1fr min-content] md:gap-x-6 items-center"
          handleSubmit={async (model) =>
            router.push(`/scopri?term=${model.term}`)
          }
          footerRenderer={({ loading, canSubmit }) => (
            <FormSubmit
              as={Button}
              loading={loading}
              canSubmit={canSubmit}
              tw="px-6 height[calc(100% - 4px)]"
              color="secondary"
            >
              {button}
            </FormSubmit>
          )}
        >
          <div tw="relative">
            <StyledField
              css={[
                tw`relative pl-12! border! border-gray-200! border-solid! py-3!`,
                isAutosuggesting && tw`border-b-0! rounded-b-none!`,
              ]}
              name="term"
              layout="elementOnly"
              placeholder={searchService}
              onChange={(name, value) => debouncedSearch(value)}
              addonBefore={
                <span tw="absolute inset-y-0 left-0 px-4 z-10 flex items-center justify-center h-full">
                  <SearchIcon tw="w-5 h-5 text-secondary" />
                </span>
              }
              required
            />
            {isAutosuggesting && (
              <div tw="absolute bg-red-100 w-full z-20 border-l border-r border-b rounded-b max-height[200px] overflow-y-auto shadow-lg">
                <MenuGroup>
                  {data.currentWorkspace.search.edges
                    .map((edge) => edge.node)
                    .map((r) => (
                      <ButtonItem
                        key={r.id}
                        onClick={() => router.push(`/scopri/${r.service.id}`)}
                      >
                        {r.service.name}
                      </ButtonItem>
                    ))}
                </MenuGroup>
              </div>
            )}
          </div>
        </Form>
        {/* <InfoItem tw="hidden border md:flex h-full">
          <div tw="min-width[20px] mr-2.5 text-primary">
            <LocationMarkerIcon tw="w-5 h-5" />
          </div>
          <p tw="text-gray-400 lg:text-base text-sm">{searchLocation}</p>
        </InfoItem> */}
      </MaxWidthContent>
    </div>
  );
};
