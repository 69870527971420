export const data = {
  name: "Signup Navigation",
  slug: "signup-navigation",
  blocks: [
    {
      shortname: "main-menu-item-1",
      name: "Main Menu Item 1",
      position: 1,
      fields: [
        {
          shortname: "accedi",
          name: "Accedi",
          type: "link",
          content: {
            label: "Accedi",
            url: "/login",
            level: 1
          }
        },
        {
          shortname: "registrati",
          name: "Registrati",
          type: "link",
          content: {
            label: "Registrati",
            url: "/register",
            level: 1,
          }
        },        
      ]
    },
    {
      shortname: "brand-logo",
      name: "Brand Logo",
      position: 2,
      fields: [
        {
          shortname: "logo",
          name: "Logo",
          type: "image",
          content: {
            alt: "Beatrice Logo",
            src: "/beatrice_logo.png",
          }
        },
      ]
    }
  ]
}