import Checkbox from '@uidu/checkbox';
import { Link, StyledField, StyledTextarea } from '../Base';

export default function FormQuestionRenderer({ formQuestion, index, ...rest }) {
  const { field } = formQuestion;
  const formQuestionsProps = {
    name: formQuestion.id,
    layout: 'elementOnly',
    required: formQuestion.required,
    ...rest,
  };
  let fieldKind = formQuestion.contactAttribute
    ? formQuestion.contactAttribute.kind
    : field.kind;

  switch (fieldKind) {
    case 'string':
      return (
        <StyledField
          type={fieldKind}
          placeholder={`${formQuestion.label}${
            formQuestion.required ? '*' : ''
          }`}
          {...formQuestionsProps}
        />
      );
    case 'address':
    //return <p>Pippo</p>;
    case 'email':
    case 'tel':
      return (
        <StyledField
          type={fieldKind}
          placeholder={`${formQuestion.label}${
            formQuestion.required ? '*' : ''
          }`}
          {...formQuestionsProps}
        />
      );
    case 'text':
      return (
        <StyledTextarea
          placeholder={`${formQuestion.label}${
            formQuestion.required ? '*' : ''
          }`}
          {...formQuestionsProps}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          label={
            <p tw="">
              Ho letto e accettato l’
              <Link href="privacy" tw="underline! hover:text-cyan-600!">
                informativa sulla privacy
              </Link>
              .*
            </p>
          }
          tw="mt-5"
          {...formQuestionsProps}
          layout="elementOnly"
        />
      );
    default:
      return null;
  }
}
