import {
  GlobeAltIcon,
  LocationMarkerIcon,
  PhoneIcon,
} from '@heroicons/react/outline';
import parse from 'html-react-parser';
import Image from 'next/image';
import tw, { styled } from 'twin.macro';
import { findByShortname } from '../../utils/common';
import { Link, MaxWidthContent } from '../Base';

const Tag = styled.div`
  ${tw`mb-3 text-sm italic font-medium text-white lg:(mb-5 text-base)`}
`;
const Heading = tw.h2`text-white font-bold uppercase text-3xl lg:text-5xl mb-2 lg:mb-3`;
const ContentGrid = styled.div`
  ${tw`relative w-full py-8 grid grid-cols-1 gap-8 lg:(pb-10 grid-cols-4)`}
`;
const InfoItem = tw.div`w-full flex text-white py-1 font-bold`;
const StyledImage = styled(Image)`
  ${tw`w-full! h-auto`}
  img {
    ${tw`w-full! h-auto!`}
  }
`;
const InfoWrapper = tw.div`col-span-full lg:(col-span-2)`;
const ImageWrapper = styled.div`
  ${tw`relative min-height[350px] rounded-xl overflow-hidden mt-0! col-span-1 lg:(min-height[450px] col-span-2 col-start-3)`}
`;
export const HeroAziende = ({ fields }) => {
  const title = findByShortname(fields, 'nome')?.content?.value ?? '';
  const label = findByShortname(fields, 'tag')?.content?.value ?? '';
  const image = findByShortname(fields, 'cover')?.content?.value?.url ?? null;
  const address = findByShortname(fields, 'indirizzo')?.content.value ?? null;
  const addressLink =
    findByShortname(fields, 'link-indirizzo')?.content.value ?? null;
  const tel = findByShortname(fields, 'telefono')?.content.value ?? null;
  const website = findByShortname(fields, 'sito')?.content.value ?? null;
  const coop = findByShortname(fields, 'cooperativa')?.content.value ?? null;

  return (
    <div tw="relative overflow-hidden pb-2 lg:(pt-10 pb-5)">
      <div tw="bg-primary absolute w-full h-full z-behind transform -skew-y-12 -top-1/2"></div>
      <MaxWidthContent>
        <ContentGrid>
          <InfoWrapper>
            {!!label && <Tag tw="col-span-2">{label}</Tag>}
            {!!title && <Heading tw="col-span-2">{title}</Heading>}
            {!!address && (
              <InfoItem tw="mt-6">
                <div tw="min-width[20px] mr-2.5">
                  <LocationMarkerIcon tw="w-5 h-5" />
                </div>
                <Link
                  href={addressLink ?? ''}
                  target="_blank"
                  tw="flex-1 hover:underline"
                >
                  {parse(address)}
                </Link>
              </InfoItem>
            )}
            {!!tel && (
              <InfoItem>
                <div tw="min-width[20px] mr-2.5">
                  <PhoneIcon tw="w-5 h-5" />
                </div>
                <Link
                  href={`tel:+39${tel.replace(/ /g, '')}`}
                  tw="flex-1 hover:underline"
                >
                  {tel}
                </Link>
              </InfoItem>
            )}
            {!!website && (
              <InfoItem>
                <div tw="min-width[20px] mr-2.5">
                  <GlobeAltIcon tw="w-5 h-5" />
                </div>
                <Link
                  as={'a'}
                  href={`https://${website.replace(/ /g, '').toLowerCase()}`}
                  target="_blank"
                  tw="flex-1 hover:underline"
                >
                  {website}
                </Link>
              </InfoItem>
            )}
          </InfoWrapper>
          <ImageWrapper>
            {!!image && (
              <StyledImage
                src={image}
                alt={''}
                layout="fill"
                objectFit="cover"
                // objectFit="scale-down"
                // objectFit="none"
                /* objectPosition={isInverted ? '0 0' : '100 0'} */
              />
            )}
          </ImageWrapper>
        </ContentGrid>
      </MaxWidthContent>
    </div>
  );
};
