import React from 'react';
import tw, { styled } from 'twin.macro';

const BotMessageGroupWrapper = styled.div`
  ${tw`flex items-end space-x-3`}
`;

const BotMessageGroupMessages = styled.div`
  /* &:last-child {
    position: relative;
    &:after {
      content: '';
      ${tw`absolute w-8 h-8 bg-gray-200 `}
    }
  } */
`;

export default function BotMessageGroup({
  title,
  children,
  author = null,
  isReverse = false,
  isVisible = false,
}: {
  title: string;
  children: React.ReactNode;
  author?: React.ReactElement;
  isReverse?: boolean;
  isVisible?: boolean;
}) {
  return (
    <BotMessageGroupWrapper
      css={[
        tw`min-width[50%]`,
        isReverse ? tw`justify-end` : tw`justify-start`,
        isVisible ? tw`` : tw`hidden`,
      ]}
    >
      {author && <div tw="flex-shrink-0">{author}</div>}
      <div tw="w-full">
        <div
          css={[
            tw`px-3 py-2 text-xs text-gray-600 uppercase`,
            isReverse ? tw`text-right` : tw`text-left`,
          ]}
        >
          {title}
        </div>
        <BotMessageGroupMessages
          css={[
            tw`flex flex-col space-y-3.5`,
            isReverse ? tw`items-end` : tw`items-start`,
          ]}
        >
          {children}
        </BotMessageGroupMessages>
      </div>
    </BotMessageGroupWrapper>
  );
}
